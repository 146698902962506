import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { type Step } from 'react-joyride'

interface JoyrideState {
  run?: boolean
  stepIndex?: number
  steps?: Step[]
  mobileSteps?: Step[]
}

export type JoyrideStateUpdate = JoyrideState

export const initialState: JoyrideState = {
  run: false,
  stepIndex: 0,
  steps: [
    {
      disableOverlayClose: true,
      placement: 'center' as Step['placement'],
      target: 'body',
    },
    {
      disableOverlayClose: false,
      floaterProps: {
        disableAnimation: true,
      },
      hideFooter: false,
      spotlightPadding: 4,
      target: '#marketing-geocoder',
      placement: 'bottom-end' as Step['placement'],
      styles: {
        options: {
          width: '275px',
          arrowOffset: '100px',
        },
      },
    },
    {
      disableOverlayClose: true,
      floaterProps: {
        disableAnimation: true,
      },
      hideFooter: false,
      spotlightPadding: 4,
      target: '#marketing-flyout-item-layers',
      placement: 'right-start' as Step['placement'],
    },
    {
      disableOverlayClose: true,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightClicks: true,
      spotlightPadding: 4,
      target: '#marketing-layer-selector', // TODO: change this to the actual id of the layer selector
      placement: 'right-start' as Step['placement'],
    },
    {
      disableOverlayClose: true,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 4,
      target: '#marketing-flyout-item-fileupload',
      placement: 'right-start' as Step['placement'],
    },
    {
      disableOverlayClose: true,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 4,
      target: '#marketing-flyout-item-filedownload',

      placement: 'right' as Step['placement'],
    },
    {
      disableOverlayClose: true,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightClicks: false,
      spotlightPadding: 4,
      target: '#marketing-draw-controls',
      placement: 'right' as Step['placement'],
    },
    {
      disableOverlayClose: true,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightClicks: false,
      spotlightPadding: 4,
      target: '#signup-button',
      placement: 'right' as Step['placement'],
    },
  ] as Step[],
  mobileSteps: [
    {
      disableOverlayClose: true,
      placement: 'center' as Step['placement'],
      target: 'body',
    },
    {
      disableOverlayClose: false,
      floaterProps: {
        disableAnimation: true,
      },
      hideFooter: false,
      spotlightPadding: 4,
      target: '#marketing-geocoder',
      placement: 'bottom-end' as Step['placement'],
      styles: {
        options: {
          width: '290px',
          arrowOffset: '100px',
        },
      },
    },
    {
      disableOverlayClose: true,
      floaterProps: {
        disableAnimation: true,
      },
      hideFooter: false,
      spotlightPadding: 4,
      target: '#marketing-flyout-item-layers',
      placement: 'right' as Step['placement'],
    },
    {
      disableOverlayClose: true,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightClicks: true,
      spotlightPadding: 4,
      target: '#marketing-flyout-item-layers', // TODO: change this to the actual id of the layer selector
      placement: 'right' as Step['placement'],
    },
    {
      disableOverlayClose: true,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 4,
      target: '#marketing-flyout-item-fileupload',
      placement: 'right' as Step['placement'],
    },
    {
      disableOverlayClose: true,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 4,
      target: '#marketing-flyout-item-filedownload',
      placement: 'top' as Step['placement'],
    },
    {
      disableOverlayClose: true,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightClicks: false,
      spotlightPadding: 4,
      target: '#marketing-draw-controls',
      placement: 'right' as Step['placement'],
    },
    {
      disableOverlayClose: true,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightClicks: false,
      spotlightPadding: 4,
      target: '#hamburger',
      placement: 'left' as Step['placement'],
      styles: {
        options: {
          width: '275px',
          arrowOffset: '100px',
        },
      },
    },
  ] as Step[],
}

const joyrideSlice = createSlice({
  name: 'joyride',
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<JoyrideStateUpdate>) => {
      const { run, stepIndex } = action.payload
      if (run !== undefined) {
        state.run = run
      }
      if (stepIndex !== undefined) {
        state.stepIndex = stepIndex
      }
    },
  },
})

export const { setState } = joyrideSlice.actions

export default joyrideSlice.reducer
