export enum FeatureType {
  background = 'background',
  circle = 'circle',
  fill = 'fill',
  'fill-extrusion' = 'fill-extrusion',
  heatmap = 'heatmap',
  hillshade = 'hillshade',
  line = 'line',
  raster = 'raster',
  symbol = 'symbol',
  unsupported = 'unsupported',
}

export type ApiFeatureType =
  | 'point_geom'
  | 'line_geom'
  | 'polygon_geom'
  | 'FillExtrusion'
  | 'Heatmap'
  | 'Hillshade'
  | 'Raster'
  | 'Symbol'
  | 'Background'
  | 'Unsupported'

export type GeoJsonFeatureType = 'Point' | 'LineString' | 'Polygon'
export type ApiUploadType = 'Control' | 'Monument' | 'Boundary'
