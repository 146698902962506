import { Layer } from '../LayerSelector/LayerTypes'
import { useDispatch } from 'react-redux'
import { setLayerVisibility } from '../Map/mapSlice'
import { LayerDataSection } from '../LayerSelector/LayerTypes'
import ScopeBadge from '../ScopeBadge/ScopeBadge'
import LeafNodeDetailButton from '../LeafNodeDetailButton/LeafNodeDetailButton'
import { ServiceType } from '../LayerSelector/LayerTypes'
import { Checkbox } from '../catalyst/checkbox'

type LayerLeafNodeProps = {
  leafNode: Layer
  section: LayerDataSection
}

const LayerLeafNode = ({ leafNode, section }: LayerLeafNodeProps) => {
  const dispatch = useDispatch()
  return (
    <li className="group pt-1 pb-2 flex items-center justify-between">
      <div
        aria-hidden="true"
        className="flex justify-between w-full cursor-pointer"
        onClick={() =>
          dispatch(
            setLayerVisibility({
              layerId: leafNode.id,
              desiredState: !leafNode.active,
              section,
            })
          )
        }
      >
        <span className="flex flex-row items-center space-x-2">
          <Checkbox color="secondary" checked={leafNode.active} />
          {/* <input
            type="checkbox"
            readOnly
            className="form-checkbox h-5 w-5 ml-2 text-secondary cursor-pointer"
            checked={leafNode.active}
          /> */}
          <h2
            className={`select-none ${leafNode.active ? 'font-semibold' : ''}`}
          >
            {leafNode.displayName}
          </h2>
        </span>
        <span className="flex items-center pr-1">
          {leafNode.source.service !== ServiceType.SILO && (
            <ScopeBadge layer={leafNode} active={leafNode.active} />
          )}
          {leafNode.source.service !== ServiceType.SILO && (
            <LeafNodeDetailButton layer={leafNode} />
          )}
        </span>
      </div>
    </li>
  )
}

export default LayerLeafNode
