export const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!, $id: Int!) {
    updateOrganization(input: $input, id: $id) {
      id
      name
      address
      city
      state
      zipCode
      industry
    }
  }
`
