import {
  Field,
  ErrorMessage,
  Label,
  Description,
} from 'src/components/catalyst/fieldset'
import { Input as CatalystInput } from 'src/components/catalyst/input'
import {
  type FieldProps,
  type LabelProps,
  type InputProps,
} from '@headlessui/react'

import { Controller, RegisterOptions, useErrorStyles } from '@redwoodjs/forms'

interface Props {
  name: string
  label?: string
  description?: string
  type?: string
  defaultValue?: string | number
  placeholderText?: string
  className?: string
  style?: React.StyleHTMLAttributes<HTMLInputElement>
  errorClassName?: string
  fieldErrorClassName?: string
  labelProps?: { className?: string } & LabelProps
  fieldProps?: FieldProps
  inputProps?: InputProps
  validation?: RegisterOptions
  placeholder?: string
}

const Input = (props: Props) => {
  const {
    name,
    label = `${props.name.charAt(0).toUpperCase()}${props.name.slice(1)}`,
    type = 'text',
    defaultValue = '',
    placeholderText = 'Enter a value',
    description,
    className = 'input',
    style = {},
    errorClassName = 'error border-red-500',
    labelProps,
    fieldProps,
    inputProps,
    validation,
  } = props

  const { className: componentClassName, style: componentStyle } =
    useErrorStyles({
      className: className,
      errorClassName: errorClassName,
      name: name,
    })

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={validation}
      render={({
        field: { onChange, value, name, ref },
        formState: { errors },
      }) => (
        <>
          <Field {...fieldProps}>
            <Label {...labelProps}>{label}</Label>
            {description && <Description>{description}</Description>}
            <CatalystInput
              className={componentClassName}
              type={type}
              name={name}
              placeholder={placeholderText}
              value={value}
              onChange={onChange}
              style={{ ...componentStyle, ...style }}
              ref={ref}
              {...inputProps}
            />
            {name in errors && (
              <ErrorMessage>{`${
                errors[name].message || `${name} is required`
              }`}</ErrorMessage>
            )}
          </Field>
        </>
      )}
    />
  )
}

export default Input
