import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { produce } from 'immer'
import { Feature as GeoJsonFeature, FeatureCollection } from 'geojson'
import {
  MapState,
  UserLayer,
  ViewState,
  CountyState,
  FlyoutConfig,
  DrawModeConfig,
  SerializableFeature,
} from './mapTypes'
import { Feature, FeatureTag } from 'types/graphql'
import {
  LayerGroup,
  Layer,
  LayerType,
  LayerActivePayload,
} from '../LayerSelector/LayerTypes'
import {
  updateLayerTree,
  updateTagLayerTree,
  pruneLayerTree,
} from 'src/lib/layerUtils'
import { baseLayers } from '../LayerSelector/layersConfig'
import { PublicLayer } from 'types/graphql'

export const initialState: MapState = {
  drawMode: {
    mode: 'simple_select',
    updatedBy: 'draw-control',
  },
  zoomThreshold: 16,
  viewState: {
    bearing: 0,
    longitude: -122.4443,
    latitude: 47.4029,
    pitch: 0,
    zoom: 10,
  },
  currentCounties: [] as CountyState[],
  baseLayers: baseLayers,
  activeUserLayers: [] as UserLayer[],
  drawingData: null,
  measurementLabels: null,
  flyoutConfig: { component: '', featureType: null },
  selectedLayer: null,
  layerData: {
    public: [] as Array<Layer | LayerGroup>,
    organization: [] as Array<Layer | LayerGroup>,
  },
  contourVisibility: false,
  featureToUpdate: null,
  lastCreatedFeature: null,
  lastEditedFeature: null,
  lastDeletedFeature: null,
  tileCacheBuster: Date.now(),
  downloadBoundingBox: null,
}

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setViewState: (state, action: PayloadAction<ViewState>) => {
      state.viewState = action.payload
    },
    setFeatureToUpdate: (state, action: PayloadAction<SerializableFeature>) => {
      state.featureToUpdate = action.payload
    },
    setContourVisibility: (state, action: PayloadAction<boolean>) => {
      state.contourVisibility = action.payload
    },
    setCurrentCounties: (state, action: PayloadAction<CountyState[]>) => {
      state.currentCounties = [...action.payload]
    },
    selectBaseLayer: (state, action: PayloadAction<Layer['id']>) => {
      state.baseLayers = [...state.baseLayers].map((layer) => {
        if (layer.id === action.payload) {
          return {
            ...layer,
            active: true,
          }
        } else {
          return {
            ...layer,
            active: false,
          }
        }
      })
    },
    setDrawingData: (state, action: PayloadAction<FeatureCollection>) => {
      state.drawingData = action.payload
    },
    setMeasurementLabels: (state, action: PayloadAction<FeatureCollection>) => {
      state.measurementLabels = action.payload
    },
    setDrawMode: (state, action: PayloadAction<DrawModeConfig>) => {
      state.drawMode = action.payload
    },
    setLastCreatedFeature: (state, action: PayloadAction<Feature>) => {
      state.lastCreatedFeature = action.payload
    },
    setLastEditedFeature: (state, action: PayloadAction<Feature>) => {
      state.lastEditedFeature = action.payload
    },
    setLastDeletedFeature: (state, action: PayloadAction<Feature>) => {
      state.lastDeletedFeature = action.payload
    },
    setFlyoutConfig: (state, action: PayloadAction<FlyoutConfig>) => {
      state.flyoutConfig = action.payload
    },

    addUserLayer: (state, action: PayloadAction<UserLayer>) => {
      const newUserLayer = {
        ...action.payload,
        id: action.payload.id,
      }

      state.activeUserLayers = [...state.activeUserLayers, newUserLayer]
    },
    removeUserLayer: (state, action: PayloadAction<UserLayer>) => {
      const layerIdToRemove = action.payload.id
      const newUserLayerArr = state.activeUserLayers.filter(
        (userLayer) => userLayer.id !== layerIdToRemove
      )
      state.activeUserLayers = newUserLayerArr
    },
    setLayerVisibility: (state, action: PayloadAction<LayerActivePayload>) => {
      state.layerData[action.payload.section] = produce(
        state.layerData[action.payload.section],
        (draft) => {
          const { layerId, desiredState } = action.payload
          const updateLayer = (layers: Array<Layer | LayerGroup>) => {
            for (const layer of layers) {
              if (layer.id === layerId) {
                layer.active = desiredState
                return
              }
              if (layer.type === LayerType.layerGroup) {
                const layerGroup = layer as LayerGroup
                updateLayer(layerGroup.children)
              }
            }
          }
          updateLayer(draft)
        }
      )
    },
    toggleUserLayerVisibility: (state, action: PayloadAction<UserLayer>) => {
      state.activeUserLayers = state.activeUserLayers.map(
        (layer: UserLayer) => {
          if (layer.id === action.payload.id) {
            return {
              ...layer,
              isRenderedInMap: !layer.isRenderedInMap,
            }
          } else {
            return layer
          }
        }
      )
    },
    setPublicLayerData: (state, action: PayloadAction<PublicLayer[]>) => {
      state.layerData.public = pruneLayerTree(
        updateLayerTree(state.layerData.public, action.payload),
        action.payload.map((layer) => layer.sourceId)
      )
    },
    setOrganizationLayerData: (state, action: PayloadAction<FeatureTag[]>) => {
      state.layerData.organization = pruneLayerTree(
        updateTagLayerTree(state.layerData.organization, action.payload),
        action.payload.map((tag) => JSON.stringify(tag.id))
      )
    },
    setTileCacheBuster: (state) => {
      state.tileCacheBuster = Date.now()
    },
    setDownloadBoundingBox: (
      state,
      action: PayloadAction<GeoJsonFeature | null>
    ) => {
      state.downloadBoundingBox = action.payload
    },
  },
})

export const {
  setViewState,
  setCurrentCounties,
  selectBaseLayer,
  setFlyoutConfig,
  setDrawingData,
  setMeasurementLabels,
  setDrawMode,
  setLastCreatedFeature,
  setLastEditedFeature,
  setLastDeletedFeature,
  setLayerVisibility,
  toggleUserLayerVisibility,
  addUserLayer,
  removeUserLayer,
  setContourVisibility,
  setFeatureToUpdate,
  setPublicLayerData,
  setOrganizationLayerData,
  setTileCacheBuster,
  setDownloadBoundingBox,
} = mapSlice.actions

export default mapSlice.reducer
