import { Badge } from 'src/components/catalyst/badge'

const BadgeComponent = () => {
  return (
    <div className="flex gap-3">
      <Badge color="lime">county</Badge>
      <Badge color="purple">State</Badge>
    </div>
  )
}

export default BadgeComponent
