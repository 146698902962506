import { ChevronUpIcon } from '@heroicons/react/24/outline'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from '@headlessui/react'
import Divider from '../TailwindUI/Divider'
import { Subheading } from '../catalyst/heading'
import { Text } from '../catalyst/text'

type SidebarSectionDisclosureProps = {
  label: string
  content: React.ReactNode
  secondaryLabel?: string
  defaultOpen?: boolean
  first?: boolean
  last?: boolean
}
const SidebarSectionDisclosure = ({
  label,
  content,
  secondaryLabel = null,
  defaultOpen = true,
  first = false,
  last = false,
}: SidebarSectionDisclosureProps) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <DisclosureButton
            className={`flex w-full ${
              first ? 'rounded-t-md' : last && !open ? 'rounded-b-md' : ''
            } justify-between items-center bg-gray-100 px-4 py-2 text-left text-md font-medium text-indigo-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75`}
          >
            <div className="inline-flex items-center w-full justify-between">
              <Subheading className="py-1 select-none">{label}</Subheading>
              {secondaryLabel && <Text className="mx-2">{secondaryLabel}</Text>}
            </div>
            <ChevronUpIcon
              className={`${
                open ? 'rotate-180 transform' : ''
              } h-5 w-5 text-gray-500`}
            />
          </DisclosureButton>
          {!last && <Divider />}
          <Transition
            enter="transition duration-100 ease-in"
            enterFrom="transform scale-75 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-75 opacity-0"
          >
            <DisclosurePanel
              className={`p-0 text-sm text-gray-500 ${
                last ? 'rounded-b-md' : ''
              }
            `}
            >
              {content}
            </DisclosurePanel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

export default SidebarSectionDisclosure
