import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

import { Form, Submit } from '@redwoodjs/forms'
import { navigate } from '@redwoodjs/router'

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]
const tiers = [
  {
    name: 'Basic',
    id: 'tier-basic',
    href: '#',
    price: { monthly: '$15', annually: '$144' },
    description: 'The essentials to work alone.',
    features: [
      'Attach Photos',
      'Offline Maps',
      'View all layers',
      'Create points/lines/polygons',
    ],
    mostPopular: false,
  },
  {
    name: 'Pro Single',
    id: 'tier-pro-single',
    href: '#',
    price: { monthly: '$30', annually: '$288' },
    description: 'All features for a single user.',
    features: [
      'Attach Photos',
      'Offline Maps',
      'View all layers',
      'Create points/lines/polygons',
      'PDF Exhibits',
      'Download Layers',
      'Upload Files',
    ],
    mostPopular: false,
  },
  {
    name: 'Pro Team Basic',
    id: 'tier-proteam-basic',
    href: '#',
    price: { monthly: '$60', annually: '$576' },
    description: 'Basic plan for team members.',
    features: [
      'Attach Photos',
      'Offline Maps',
      'View all layers',
      'Create points/lines/polygons',
      'Multi-user database',
    ],
    mostPopular: true,
  },
  {
    name: 'Pro Team Member',
    id: 'tier-proteam-member',
    href: '#',
    price: { monthly: '$60', annually: '$576' },
    description: 'All features for team members.',
    features: [
      'Attach Photos',
      'Offline Maps',
      'View all layers',
      'Create User Layers',
      'Create points/lines/polygons',
      'Multi-user database',
      'PDF Exhibits',
      'Download Layers',
      'Upload Files',
    ],
    mostPopular: false,
  },
  {
    name: 'Pro Team Lead',
    id: 'tier-proteam-lead',
    href: '#',
    price: { monthly: '$90', annually: '$864' },
    description: 'All features for team lead.',
    features: [
      'Attach Photos',
      'Offline Maps',
      'View all layers',
      'Create points/lines/polygons',
      'Multi-user database',
      'PDF Exhibits',
      'Download Layers',
      'Upload Files',
    ],
    mostPopular: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Pricing() {
  const [frequency, setFrequency] = useState(frequencies[0])

  const handleNavigateToMap = () => {
    navigate('/')
  }

  return (
    <div className="lg:pb-8 lg:pt-0 flex justify-center items-center py-32 w-[100vw] rounded-lg pt-0 ">
      <Form onSubmit={handleNavigateToMap}>
        <div className=" px-6 lg:px-8 mx-auto w-[100vw]">
          <div className="mx-auto max-w-4xl sm:text-center">
            <div className="lg:h-24 h-24">
              <img
                src="/silo-logo.svg"
                alt="silo logo"
                className="h-full w-full"
              />
            </div>
          </div>
          <p className="mx-auto  max-w-2xl text-center text-lg leading-8 text-gray-600">
            Choose an affordable plan that fits your needs.
          </p>
          <div className="mt-4 flex justify-center">
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
            >
              <RadioGroup.Label className="sr-only">
                Payment frequency
              </RadioGroup.Label>
              {frequencies.map((option) => (
                <RadioGroup.Option
                  key={option.value}
                  value={option}
                  className={({ checked }) =>
                    classNames(
                      checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
                      'cursor-pointer rounded-full px-2.5 py-1'
                    )
                  }
                >
                  <span>{option.label}</span>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
          <div className="lg:flex-row w-full lg:justify-center lg:items-center mt-10  flex flex-col gap-4 md:max-w-xl  lg:max-w-4xl xl:mx-0 xl:max-w-none ">
            {tiers.map((tier) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'ring-2 ring-indigo-600 lg:w-[275px] lg:h-[575px]'
                    : 'ring-1 ring-gray-600 lg:w-[275px] lg:h-[575px]',
                  'rounded-3xl p-8 lg:w-[275px] lg:h-[575px]'
                )}
              >
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                    'text-lg font-semibold leading-8'
                  )}
                >
                  {tier.name}
                </h3>
                <p className="mt-4 text-sm leading-6 text-gray-600 flex-nowrap">
                  {tier.description}
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-2xl font-bold tracking-tight text-gray-900">
                    {tier.price[frequency.value]}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    {frequency.priceSuffix}
                  </span>
                </p>
                <a
                  href={tier.href}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                      : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                    'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  )}
                >
                  Buy plan
                </a>
                <ul className="mt-8 space-y-2 text-sm leading-6 text-gray-600">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="rw-button-group w-full flex justify-center">
          <Submit className="rw-button rw-button-blue w-[25%] mt-4 p-2">
            Submit
          </Submit>
        </div>
      </Form>
    </div>
  )
}
