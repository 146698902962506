import { Form, SubmitHandler } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'
import TeamMembersCell from 'src/components/TeamMembersCell'
import { UserRoles } from 'src/roles'
import { Button } from 'src/components/catalyst/button'
import { Heading } from 'src/components/catalyst/heading'
import { Text } from 'src/components/catalyst/text'
import Input from 'src/components/catalyst/rw/Input'
import { useForm } from 'react-hook-form'
import { InvitationStatus } from 'src/types/enums'

const ADD_TEAM_MEMBER_MUTATION = gql`
  mutation TeamSectionAddTeamMember($input: AddTeamMemberInput!) {
    addTeamMember(input: $input) {
      id
      email
    }
  }
`

const UPDATE_INVITATION_MUTATION = gql`
  mutation UpdateInvitation($id: Int!, $input: UpdateInvitationInput!) {
    updateInvitation(id: $id, input: $input) {
      id
      status
    }
  }
`

const REMOVE_TEAM_MEMBER_MUTATION = gql`
  mutation RemoveTeamMember($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`

export default function TeamSection({ currentUser }) {
  const formMethods = useForm()
  const [addTeamMember] = useMutation(ADD_TEAM_MEMBER_MUTATION, {
    update: (cache, { data: { addTeamMember } }) => {
      cache.modify({
        fields: {
          organizationUsers: (existingUsers, { toReference }) => {
            const newUserRef = toReference(addTeamMember)
            return [...existingUsers, newUserRef]
          },
        },
      })
    },
    onCompleted: () => {
      toast.success('User Invited to Organization')
      formMethods.reset()
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [removeTeamMember] = useMutation(REMOVE_TEAM_MEMBER_MUTATION, {
    update: (cache, { data: { updateUser } }) => {
      cache.modify({
        fields: {
          organizationUsers: (existingUsersRefs, { readField }) => {
            return existingUsersRefs.filter(
              (userRef) => readField('id', userRef) !== updateUser.id
            )
          },
        },
      })
    },
    onCompleted: () => {
      toast.success('User Removed from Organization')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [updateInvitation] = useMutation(UPDATE_INVITATION_MUTATION, {
    update: (cache, { data: { updateInvitation } }) => {
      cache.modify({
        fields: {
          pendingInvitations(existingInvitationsRefs, { readField }) {
            return existingInvitationsRefs.filter(
              (invitationRef) =>
                readField('id', invitationRef) !== updateInvitation.id
            )
          },
        },
      })
    },
    onCompleted: () => {
      toast.success('Invitation Revoked')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const allowedRoles: UserRoles[] = [UserRoles.owner, UserRoles.admin]

  type FormFields = {
    memberEmail: string
  }

  const onSubmit: SubmitHandler<FormFields> = (data) => {
    const invitedUser = {
      email: data.memberEmail,
      organizationId: currentUser.organizationId,
      adminId: currentUser.id,
    }
    addTeamMember({ variables: { input: invitedUser } })
  }

  const handleDelete = async (id: number, deleteType: string) => {
    if (deleteType === 'MEMBER') {
      const updatedUser = {
        id,
        organizationId: null,
      }
      removeTeamMember({ variables: { input: updatedUser } })
    } else if (deleteType === 'INVITEE') {
      const updatedInvitation = {
        status: InvitationStatus.REVOKED,
      }
      updateInvitation({ variables: { id: id, input: updatedInvitation } })
    }
  }

  return (
    <>
      <div className="mx-auto w-full space-y-4 sm:space-y-8 lg:mx-0 lg:max-w-none">
        <section>
          <Heading level={1} className="text-xl font-semibold leading-7 ">
            Your Team
          </Heading>
          <Text className="mt-1 text-sm leading-6 ">
            Manage your team members and invite them to join your organization.
            Invited members will receive an email from registration@silogeo.com
            to complete their account setup. Remind them to check their spam
            folders.
          </Text>
        </section>
        <section className="mx-auto w-full lg:mx-0 lg:max-w-none">
          {allowedRoles.includes(currentUser.roleId) && (
            <Form
              formMethods={formMethods}
              onSubmit={onSubmit}
              className=" pt-2 flex flex-col sm:flex-row space-y-4 justify-between items-center gap-x-4 w-full"
            >
              <Input
                name="memberEmail"
                label="Add a team member"
                placeholderText="Enter email address"
                className="w-full sm:w-3/4"
                fieldProps={{ className: 'w-full' }}
                inputProps={{ className: 'w-full' }}
              />

              <Button
                className="w-full sm:w-1/4 self-end mb-0"
                type="submit"
                color="primary"
              >
                Add Member
              </Button>
            </Form>
          )}
          <TeamMembersCell
            organizationId={currentUser.organizationId as number}
            allowedRoles={allowedRoles}
            handleDelete={handleDelete}
          />
        </section>
      </div>
    </>
  )
}
