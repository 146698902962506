import { useState } from 'react'
import { useAuth } from 'src/auth'
import UserInput from './userInput'
import UpdateOrganization from './updateOrganization'
import { LoaderIcon } from '@redwoodjs/web/dist/toast'
import { useQuery } from '@apollo/client'
import { Button } from 'src/components/catalyst/button'
import { Heading, Subheading } from 'src/components/catalyst/heading'
import { Text } from 'src/components/catalyst/text'

const PROFILE_QUERY = gql`
  query organizationProfile($orgId: Int!, $id: Int!) {
    organization(id: $orgId) {
      id
      name
    }
    user(id: $id) {
      id
      fullName
      email
      title
      roleId
    }
  }
`

export default function ProfileSection() {
  const [updateOrgIsOpen, setUpdateOrgIsOpen] = useState(false)
  const [updateNameIsOpen, setUpdateNameIsOpen] = useState(false)
  const [updateTitleIsOpen, setUpdateTitleIsOpen] = useState(false)

  const { currentUser } = useAuth()

  const { data, loading } = useQuery(PROFILE_QUERY, {
    variables: {
      orgId: currentUser?.organizationId,
      id: currentUser.id,
    },
    skip: !currentUser.organizationId,
  })

  const { logOut } = useAuth()

  return (
    <>
      <div className="mx-auto w-full space-y-4 sm:space-y-8 lg:mx-0 lg:max-w-none">
        <section>
          <div>
            <Heading level={1} className="text-xl font-semibold leading-7">
              Your Profile
            </Heading>
            <Text className="mt-1 text-sm leading-6 text-gray-500">
              This information will be visible to team mates.
            </Text>
          </div>
        </section>
        <section>
          <Subheading className="my-2 text-base font-semibold leading-7 ">
            User Information
          </Subheading>
          <dl className="space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <Text className="  sm:w-64 sm:flex-none sm:pr-6 font-semibold">
                Full name
              </Text>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-500">
                  {currentUser.fullName !== undefined ? (
                    <div>{data?.user.fullName}</div>
                  ) : (
                    <div>N/A</div>
                  )}
                </div>
                <UserInput
                  open={updateNameIsOpen}
                  label={'Update Name'}
                  identifier={'fullName'}
                  setIsOpen={setUpdateNameIsOpen}
                />
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <Text className="  sm:w-64 sm:flex-none sm:pr-6 font-semibold">
                Email address
              </Text>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-500">
                  {currentUser.email !== undefined ? (
                    <div>{data?.user.email}</div>
                  ) : (
                    <div>N/A</div>
                  )}
                </div>{' '}
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <Text className="  sm:w-64 sm:flex-none sm:pr-6 font-semibold">
                Title
              </Text>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-500">
                  {currentUser.title !== undefined ? (
                    <div>{data?.user.title}</div>
                  ) : (
                    <div>N/A</div>
                  )}
                </div>
                <UserInput
                  open={updateTitleIsOpen}
                  label={'Update Title'}
                  identifier={'title'}
                  setIsOpen={setUpdateTitleIsOpen}
                />
              </dd>
            </div>
          </dl>
        </section>

        <section>
          <Subheading className="my-2 text-base font-semibold leading-7 ">
            Organizations
          </Subheading>
          <ul className="divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <li className="flex justify-between gap-x-6 py-6">
              <div className="font-medium text-gray-500">
                {loading ? (
                  <LoaderIcon />
                ) : data?.organization === undefined ? (
                  <div>No Organization</div>
                ) : (
                  <div>{data.organization.name}</div>
                )}
              </div>
              <UpdateOrganization
                setIsOpen={setUpdateOrgIsOpen}
                open={updateOrgIsOpen}
              />
            </li>
          </ul>
        </section>
      </div>
      <div className="sm:px-20 lg:px-0 lg:flex lg:flex-1 lg:justify-start py-8">
        <Button color="primary" onClick={() => logOut()}>
          Log out
        </Button>
      </div>
    </>
  )
}
