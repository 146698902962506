import React from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import Logo from '../Logo/Logo'
import { twMerge } from 'tailwind-merge'

interface Props {
  isOpen: boolean
  children: React.ReactNode
  className?: string
  showLogo?: boolean
  logoClassName?: string
  onClose?: () => void
  closeButtonClassName?: string
  bgColor?: string
}

const FullscreenOverlay = ({
  isOpen,
  onClose,
  className = '',
  children,
  showLogo = false,
  logoClassName = '',
  closeButtonClassName = '',
  bgColor = 'bg-[#30465E]',
}: Props) => {
  return (
    isOpen && (
      <div
        className={`${className} fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40`}
      >
        <div className={`flex flex-col space-x-0 ${bgColor} w-full h-full`}>
          <div className="h-[8vh] pl-[1.5rem] pr-[1rem] py-[.3rem] flex flex-row w-full justify-between items-center">
            {showLogo ? (
              <Logo className={twMerge('w-[100px]', logoClassName)} />
            ) : (
              <div className="w-[100px] flex justify-center items-center" />
            )}
            {onClose && (
              <button
                onClick={onClose}
                className={twMerge(
                  'p-4 self-end text-gray-600 hover:text-gray-900 focus:outline-none',
                  closeButtonClassName
                )}
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            )}
          </div>
          <div className="p-5 h-full w-full overflow-auto">{children}</div>
        </div>
      </div>
    )
  )
}

export default FullscreenOverlay
