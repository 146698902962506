import { Fragment, useState, useEffect } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, ChevronDownIcon } from '@heroicons/react/24/outline'
import Background from 'public/marketing/pugit.png'
import { Button } from 'src/components/catalyst/button'
import { auth0, defaultAuth0Params } from 'src/auth'
import Scope from 'src/components/Resources/Scope'
import { useLazyQuery } from '@apollo/client'
import { Form, FormError } from '@redwoodjs/forms'
import { navigate, routes } from '@redwoodjs/router'
import Input from 'src/components/catalyst/rw/Input'
import { useAuth } from 'src/auth'
import FullscreenOverlay from 'src/components/FullscreenOverlay/FullscreenOverlay'
import LogoButton from 'src/components/LogoButton/LogoButton'
import { MapIcon } from '@heroicons/react/24/outline'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import LoadingScreenLogo from 'src/components/LoadingScreenLogo/LoadingScreenLogo'

const QUERY = gql`
  query verifyAuthCode($authCode: String!, $email: String!) {
    verifyAuthCode(authCode: $authCode, email: $email) {
      success
      message
    }
  }
`

export default function HomePage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [gateModalOpen, setGateModalOpen] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const [verifyAuthCode, { error, loading }] = useLazyQuery(QUERY)
  const { isAuthenticated, loading: loadingAuth } = useAuth()

  const handleCheckAuthCode = async (input) => {
    const response = await verifyAuthCode({ variables: input })
    const { data, error } = response
    const { message, success } = !error && data.verifyAuthCode

    if (success) {
      auth0.loginWithRedirect({
        ...defaultAuth0Params,
        authorizationParams: {
          ...defaultAuth0Params.authorizationParams,
          allow_signup: true,
          allow_login: false,
          initial_screen: 'signUp',
        },
      })
    } else {
      setValidationMessage(message)
    }
  }

  const handleLogin = () => {
    auth0.loginWithRedirect({
      ...defaultAuth0Params,
      authorizationParams: {
        ...defaultAuth0Params.authorizationParams,
        allow_signup: false,
        allow_login: true,
        initial_screen: 'login',
      },
    })
  }

  useEffect(() => {
    if (!loadingAuth && isAuthenticated) {
      navigate(routes.map())
    }
  }, [isAuthenticated, loadingAuth])

  return loadingAuth ? (
    <FullscreenOverlay isOpen={true}>
      <div className="h-full w-full flex flex-col items-center justify-center">
        <LoadingScreenLogo className="h-auto w-[200px]" />
      </div>
    </FullscreenOverlay>
  ) : (
    <div className="bg-white h-[100vh] overflow-y-hidden">
      <header className="absolute inset-x-0 top-0 z-50 ">
        <div className="mx-auto max-w-screen-xl">
          <div className="px-6 pt-6 lg:max-w-3xl lg:pl-8 lg:pr-0">
            <nav
              className="flex items-center justify-between content-center lg:justify-start"
              aria-label="Global"
            >
              <LogoButton handleClick={() => setGateModalOpen(false)} />
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700 lg:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="hidden lg:ml-12 lg:flex lg:gap-x-14">
                <Button
                  plain={true}
                  className="mt-0"
                  type="button"
                  onClick={handleLogin}
                >
                  Sign In
                </Button>
                {!gateModalOpen && (
                  <Button
                    plain={true}
                    className="mt-0"
                    type="button"
                    onClick={() => setGateModalOpen(true)}
                  >
                    Register
                  </Button>
                )}
                <Popover.Group className="hidden lg:flex lg:gap-x-12 items-center ">
                  <Popover className="relative ">
                    <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                      Scope
                      <ChevronDownIcon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute -left-8 top-full w-[500px] max-h-[85vh] z-10 mt-3 flex rounded-xl bg-white p-2 shadow-lg overflow-y-auto ">
                        <Scope />
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </Popover.Group>
              </div>
            </nav>
          </div>
        </div>
        <FullscreenOverlay
          isOpen={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          logoClassName="pt-[38px] w-[96px]"
          closeButtonClassName="p-0 pr-3"
          bgColor="bg-white"
        >
          <div className="p-2 flex flex-col content-start">
            <div>
              <Button
                plain={true}
                className="mt-0"
                type="button"
                onClick={handleLogin}
              >
                Sign In
              </Button>
            </div>
            <div>
              <Button
                plain={true}
                className="mt-0"
                type="button"
                onClick={() => {
                  setGateModalOpen(true)
                  setMobileMenuOpen(false)
                }}
              >
                Register
              </Button>
            </div>
            <div>
              <Disclosure>
                {({ open }) => (
                  <>
                    <DisclosureButton className="flex flex-row p-3.5 py-2 space-x-2 font-bold content-center">
                      <h2
                        className={`select-none ${open ? 'font-semibold' : ''}`}
                      >
                        Scope
                      </h2>
                      <ChevronUpIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-auto w-5 text-gray-500 self-center`}
                      />
                    </DisclosureButton>
                    <DisclosurePanel className="text-gray-500">
                      <Scope />
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </FullscreenOverlay>
      </header>

      <div className="relative h-full">
        <div className="mx-auto max-w-screen-xl">
          <div className="relative z-10 lg:pt-14 lg:w-full lg:max-w-2xl">
            <div className="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-56 lg:pr-0 mt-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  The GIS solution for the AEC industry.
                </h1>
                <div className="mt-6 text-lg leading-8 text-gray-600">
                  <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                      A multi-user & multi-discipline database to create and
                      store data.
                    </li>
                    <li>
                      View and export public GIS data, pertinent to land
                      development, planning, and feasibility.
                    </li>
                    <li>
                      Exported data comes in file formats compatible with all
                      drafting software.
                    </li>
                  </ul>
                </div>

                <div className="mt-10 flex items-center gap-x-6">
                  <Button
                    color="primary"
                    onClick={() => navigate(routes.marketingmap())}
                  >
                    Take the Tour <span aria-hidden="true">→</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-[42%] hidden lg:flex">
          <img
            className="object-cover lg:h-full lg:w-full"
            src={Background}
            alt="tacoma washington"
          />
        </div>
      </div>

      <FullscreenOverlay
        isOpen={gateModalOpen}
        className="z-[40]"
        showLogo={false}
        bgColor="bg-white"
      >
        <div className="flex flex-col space-x-0 w-full h-full">
          <section className="p-8 flex flex-col gap-4 justify-center w-full h-full">
            <div className="flex flex-col space-y-5 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 self-center">
              <h2 className="font-semibold text-lg">
                Please enter the code you received from a Silo team member.
              </h2>
              <Form
                onSubmit={handleCheckAuthCode}
                className="flex flex-col space-y-4"
              >
                <Input
                  label="Auth Code"
                  name="authCode"
                  validation={{ required: 'Auth Code required' }}
                  inputProps={{ disabled: loading }}
                />
                <Input
                  label="Registered Email"
                  name="email"
                  validation={{ required: 'Email required' }}
                  inputProps={{ disabled: loading }}
                />
                <Button
                  color="primary"
                  className="mt-4"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Validating...' : 'Submit'}
                </Button>
                <FormError error={error} />
                {validationMessage && (
                  <p className="text-sm text-red-500">{validationMessage}</p>
                )}
              </Form>
              <div className="pt-4 flex flex-row gap-4 w-full items-center justify-center">
                <h4>{"Don't have a code?"}</h4>
                <Button
                  outline
                  type="button"
                  onClick={() => navigate(routes.lead())}
                >
                  Request Access
                </Button>
              </div>
              <div className="flex flex-col items-center space-y-4">
                <span>or</span>
                <button onClick={() => setGateModalOpen(false)}>
                  <div className="flex flex-row space-x-1 mr-2 text-sm font-semibold leading-6 text-secondary">
                    <MapIcon className="h-6 w-6" />
                    <span className="text-secondary">Return Home</span>
                    <span aria-hidden="true">&rarr;</span>
                  </div>
                </button>
              </div>
            </div>
          </section>
        </div>
      </FullscreenOverlay>
    </div>
  )
}
