import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/catalyst/table'

const TableComponent = ({ users }) => {
  return (
    <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] w-full">
      <TableHead>
        <TableRow>
          <TableHeader>Member</TableHeader>
          <TableHeader>Role</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user) => (
          <TableRow key={user.email}>
            <TableCell>
              <div className="flex items-center gap-4">
                <div>
                  <div className="font-medium">{user.name}</div>
                  <div className="text-zinc-500">
                    <div className="hover:text-zinc-700">{user.email}</div>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell className="text-zinc-500">{user.role}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default TableComponent
