import React, { useState, useEffect } from 'react'
import { MoonIcon } from '@heroicons/react/24/outline'

const DarkModeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(false)

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  return (
    <button onClick={() => setIsDarkMode(!isDarkMode)}>
      <MoonIcon className="h-[30px]" />
    </button>
  )
}

export default DarkModeToggle
