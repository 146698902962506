import React, { useEffect } from 'react'
import { useAuth } from 'src/auth'
import Sentry from 'src/lib/sentry'

interface Props {
  children: React.ReactNode
}

const SentryProvider = ({ children }: Props) => {
  const { currentUser } = useAuth()

  useEffect(() => {
    // Set current user for sentry logger once user has logged in
    if (currentUser) {
      Sentry.setUser(currentUser)
    }
  }, [currentUser])

  return <>{children}</>
}

export default SentryProvider
