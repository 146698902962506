import { Description, Label } from 'src/components/catalyst/fieldset'
import { Switch, SwitchField } from 'src/components/catalyst/switch'

const SwitchComponent = () => {
  return (
    <SwitchField>
      <Label>Switch</Label>
      <Description>To turn off and on</Description>
      <Switch name="off_on" color="primary" defaultChecked />
    </SwitchField>
  )
}

export default SwitchComponent
