import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { FeatureTypeTable } from 'src/pages/SettingsPage/FeatureTypeTable'
import { FeatureType } from 'src/types/features'
import { RenderConfig } from 'types/graphql'
import { getFeatureTypeLabel } from 'src/lib/featureUtils'
import { Text } from '../catalyst/text'

type RenderConfigDisclosureProps = {
  open: boolean
  renderConfigs: RenderConfig[]
  featureType: FeatureType
}

const RenderConfigDisclosure = ({
  open,
  renderConfigs,
  featureType,
}: RenderConfigDisclosureProps) => {
  return (
    <Disclosure defaultOpen={open}>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-secondary bg-opacity-20 px-4 py-4 text-left text-sm font-medium text-text hover:bg-primary hover:bg-opacity-25 focus:outline-none focus-visible:ring focus-visible:ring-primary">
            <div className="flex items-center gap-x-2">
              <Text
                className={`select-none text-indigo-800 ${
                  open ? 'font-semibold' : ''
                }`}
              >
                {`${getFeatureTypeLabel(featureType)}s`}
              </Text>
              <p className="text-secondary">{`(${renderConfigs.length})`}</p>
            </div>
            <ChevronUpIcon
              className={`${
                open ? 'rotate-180 transform' : ''
              } h-5 w-5 text-primary`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
            <FeatureTypeTable
              renderConfigs={renderConfigs}
              featureType={featureType}
            />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default RenderConfigDisclosure
