import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { FC, ReactNode } from 'react'
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from 'src/components/catalyst/dropdown'
import { Field, Label } from 'src/components/catalyst/fieldset'
import { Text } from 'src/components/catalyst/text'

interface DropdownItemProps {
  id: number
  name: string
  icon?: ReactNode
}

export const dropdownItems: DropdownItemProps[] = [
  {
    id: 1,
    name: 'Dropdown Item 1',
  },
  {
    id: 2,
    name: 'Dropdown Item 2',
  },
  {
    id: 3,
    name: 'Dropdown Item 3',
  },
  {
    id: 4,
    name: 'Dropdown Item 4',
  },
  {
    id: 5,
    name: 'Dropdown Item 5',
  },
]

interface DropdownComponentProps {
  label?: string
  items?: []
  children?: ReactNode
}

const DropdownComponent: FC<DropdownComponentProps> = ({
  label,
  items = dropdownItems,
  children,
}) => {
  return (
    <Field className="font-roboto flex flex-col">
      {label && <Label>Dropdown Label</Label>}
      <Dropdown>
        <DropdownButton
          color="white"
          className="w-form-control h-form-control mt-dropdown-label dark:bg-white/5"
        >
          <Text className="w-full flex justify-start font-normal text-muted">
            Dropdown Button
          </Text>
          <ChevronDownIcon
            className="max-h-[15px] dark:text-white"
            color="black"
          />
        </DropdownButton>
        <DropdownMenu className="w-full">
          {items.length === 0 && <DropdownItem>No items</DropdownItem>}
          {items.map((item) => (
            <DropdownItem key={item.id}>
              <div className="flex items-center">{item.name}</div>
            </DropdownItem>
          ))}
          <DropdownItem>
            New Item
            {children}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Field>
  )
}

export default DropdownComponent
