import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from 'src/components/catalyst/dropdown'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/catalyst/table'
import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline'

const ComplexTableComponent = ({ types }) => {
  return (
    <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
      <TableHead>
        <TableRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Color</TableHeader>
          <TableHeader>Line Width</TableHeader>
          <TableHeader>Line Cap</TableHeader>
          <TableHeader className="relative w-0">
            <span className="sr-only">Actions</span>
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {types.map((type) => (
          <TableRow key={type.id}>
            <TableCell className="font-medium">{type.name}</TableCell>
            <TableCell>
              <div
                style={{ backgroundColor: type.color }}
                className="rounded-full  w-[40px] h-[40px] "
              ></div>
            </TableCell>
            <TableCell className="text-zinc-500">{type.lineWidth}</TableCell>
            <TableCell className="text-zinc-500">{type.lineCap}</TableCell>
            <TableCell>
              <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                <Dropdown>
                  <DropdownButton plain aria-label="More options">
                    <EllipsisHorizontalCircleIcon />
                  </DropdownButton>
                  <DropdownMenu anchor="bottom end">
                    <DropdownItem>View</DropdownItem>
                    <DropdownItem>Edit</DropdownItem>
                    <DropdownItem>Delete</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ComplexTableComponent
