import type {
  Invitation,
  TeamMembersQuery,
  TeamMembersQueryVariables,
  User,
} from 'types/graphql'

import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'
import { UserRoles } from 'src/roles'
import { TeamMembersList } from 'src/pages/SettingsPage/TeamMembersList'

interface TeamMembersCellProps {
  organizationId: number
  allowedRoles: UserRoles[]
  handleDelete: (id: number, type: string) => Promise<void>
}

export const QUERY: TypedDocumentNode<
  TeamMembersQuery,
  TeamMembersQueryVariables
> = gql`
  query TeamMembersQuery($organizationId: Int!) {
    organizationUsers(organizationId: $organizationId) {
      id
      fullName
      title
      email
      roleId
    }
    pendingInvitations(organizationId: $organizationId) {
      id
      status
      user {
        email
        fullName
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>No Currently Active Team Members</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  organizationUsers,
  pendingInvitations,
  allowedRoles,
  handleDelete,
}: CellSuccessProps<TeamMembersQuery> & TeamMembersCellProps) => {
  return (
    <>
      <div className="mt-8">
        <TeamMembersList
          data={organizationUsers as User[]}
          allowedRoles={allowedRoles}
          handleDelete={handleDelete}
        />
        {pendingInvitations.length > 0 && (
          <TeamMembersList
            data={pendingInvitations as Invitation[]}
            allowedRoles={allowedRoles}
            handleDelete={handleDelete}
          />
        )}
      </div>
    </>
  )
}
