import { Description, Field, Label } from 'src/components/catalyst/fieldset'
import {
  Listbox,
  ListboxDescription,
  ListboxLabel,
  ListboxOption,
} from 'src/components/catalyst/listbox'
import { Avatar } from 'src/components/catalyst/avatar'
import ProfileImage from './me.jpg'

const users = [
  { name: 'Kyle', email: 'kylem147@gmail.com', avatar: ProfileImage },
  { name: 'Andrew', email: 'andrewboylecodes@gmail.com', avatar: ProfileImage },
  { name: 'Matthew', email: 'matthew@gmail.com', avatar: ProfileImage },
  { name: 'Ryan', email: 'salishgeo@gmail.com', avatar: ProfileImage },
  { name: 'Kent', email: 'kent@gmail.com', avatar: ProfileImage },
]

const ListboxWithIcons = () => {
  return (
    <Field className="w-form-control-wide">
      <Label>List Box</Label>
      <Description>This will be visible to clients on the project.</Description>

      <Listbox name="users" placeholder="Users..." className="h-form-control">
        {users.map((user) => {
          return (
            <ListboxOption
              key={user.email}
              value={user.email}
              className="flex items-center"
            >
              <Avatar src={user.avatar} square />
              <ListboxLabel>{user.name}</ListboxLabel>
              <ListboxDescription>@{user.email}</ListboxDescription>
            </ListboxOption>
          )
        })}
      </Listbox>
    </Field>
  )
}

export default ListboxWithIcons
