import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMap } from 'react-map-gl'
import { toast } from '@redwoodjs/web/dist/toast'
import {
  setDownloadBoundingBox,
  setDrawMode,
  setFlyoutConfig,
} from '../Map/mapSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { accumulateActiveLayers } from 'src/lib/layerUtils'
import { downloadVisibleLayers } from 'src/services/geoservice'
import FlyoutAction from '../FlyoutAction/FlyoutAction'
import { useAuth } from 'src/auth'
import { bbox } from '@turf/bbox'
import { Fieldset, Label } from '../catalyst/fieldset'
import { Select } from '../catalyst/select'
import { Checkbox, CheckboxField } from '../catalyst/checkbox'
import InfoPopUp from '../InfoPopUp/InfoPopUp'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { logger } from 'src/lib/logger'
import Sentry from 'src/lib/sentry'

export const FileDownloadComponent = ({ onClose }) => {
  const { siloMap } = useMap()
  const { currentUser, isAuthenticated } = useAuth()
  const dispatch = useDispatch()
  const layerData = useSelector((state: RootState) => state.map.layerData)
  const baseLayers = useSelector((state: RootState) => state.map.baseLayers)
  const selectedLayer = baseLayers.find((layer) => layer.active)
  const [fileType, setFileType] = useState('shp')
  const [isDrawing, setIsDrawing] = useState(false)
  const downloadBoundingBox = useSelector(
    (state: RootState) => state.map.downloadBoundingBox
  )
  const [downloadBaseMapSelected, setDownloadBaseMapSelected] = useState(false)

  const getAoiBounds = useCallback(() => {
    if (downloadBoundingBox && downloadBoundingBox.geometry) {
      const [minLng, minLat, maxLng, maxLat] = bbox(downloadBoundingBox)
      return {
        xmin: minLng,
        ymin: minLat,
        xmax: maxLng,
        ymax: maxLat,
      }
    } else {
      const mapRef = siloMap.getMap()
      const bounds = mapRef.getBounds()
      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()

      return {
        xmin: sw.lng,
        ymin: sw.lat,
        xmax: ne.lng,
        ymax: ne.lat,
      }
    }
  }, [downloadBoundingBox, siloMap])

  const aoi = useMemo(() => getAoiBounds(), [getAoiBounds]) // TO DO: update this function to depend on the bounds and not just the reference to the function

  const handleDownloadClick = () => {
    dispatch(
      setDrawMode({ mode: 'draw_rectangle', updatedBy: 'flyout-manager' })
    )
    setIsDrawing(true)
  }

  useEffect(() => {
    if (isDrawing && downloadBoundingBox) {
      downloadAllLayers()
      setIsDrawing(false)
      dispatch(
        setDrawMode({ mode: 'simple_select', updatedBy: 'flyout-manager' })
      )
      setTimeout(() => {
        dispatch(setDownloadBoundingBox(null)) // Push this to next iteration of event loop to enable the control instance to handle deletion of the drawn bbox
      })
    }
  }, [isDrawing, downloadBoundingBox, dispatch])

  const downloadAllLayers = async () => {
    const publicLayers = accumulateActiveLayers(layerData.public)
    const orgLayers = accumulateActiveLayers(layerData.organization)

    if (!publicLayers.length && !orgLayers.length && !downloadBaseMapSelected) {
      toast('No layers selected for download.')
      return
    }

    toast('Download started...')

    try {
      const baseMapUrl = downloadBaseMapSelected
        ? selectedLayer.source.url
        : null

      await downloadVisibleLayers(
        aoi,
        currentUser,
        publicLayers,
        orgLayers,
        fileType,
        baseMapUrl
      )
      toast('Download completed successfully.')
    } catch (error) {
      logger.error(error, 'Download failed')
      Sentry.captureException(error)
      await Sentry.flush(2000)
      toast('Download failed for some layers.')
    }

    dispatch(setFlyoutConfig({ component: '' }))
  }

  return (
    <FlyoutAction
      onClose={onClose}
      headerText="Download Layers"
      buttonText="Clip and Download"
      handleSubmit={handleDownloadClick}
    >
      <div className="flex flex-col gap-2 w-full">
        <Fieldset>
          <Label>Select Output File Type</Label>
          <Select
            id="fileType"
            name="fileType"
            defaultValue="shp"
            onChange={(e) => setFileType(e.target.value)}
          >
            <option value="shp">SHP</option>
            <option value="dxf">DXF</option>
          </Select>
        </Fieldset>
      </div>
      <Fieldset className="py-2">
        <CheckboxField>
          <Checkbox
            name="exportBaseMap"
            checked={downloadBaseMapSelected}
            onChange={() =>
              setDownloadBaseMapSelected(!downloadBaseMapSelected)
            }
            disabled={!isAuthenticated}
          />
          <Label>
            Export Base Map
            <InfoPopUp
              message="The basemap will export at the highest resolution for image sizes
            less than 1GB. This equates to an area approximately 0.5 mi x 0.5 mi
            or less. Users can export images larger in area, but can expect
            resolution to degrade."
              position="bottom-end"
            >
              <InformationCircleIcon className="h-5 w-5 ml-2 text-gray-400" />
            </InfoPopUp>
          </Label>
        </CheckboxField>
      </Fieldset>
    </FlyoutAction>
  )
}
