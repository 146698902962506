import { Layer, ServiceType } from '../LayerSelector/LayerTypes'
import { Popover } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

type LeafNodeDetailButtonProps = {
  layer: Layer
}

const LeafNodeDetailButton = ({ layer }: LeafNodeDetailButtonProps) => {
  return (
    <div className="relative">
      <Popover>
        <Popover.Button className={'-mr-4 px-1 pt-1'}>
          <EllipsisVerticalIcon className="h-5 w-5 text-gray-500" />
        </Popover.Button>
        <Popover.Panel
          onClick={(e) => e.stopPropagation()}
          className="absolute right-[-8px] bottom-[34px] w-64 h-20 z-10 mt-3 flex rounded-md bg-white p-0 shadow-lg  ring-gray-900/5 truncate"
        >
          <Popover.Button className={'relative w-64 h-full p-1'}>
            <ul className="w-64 h-full p-2 grid grid-cols-1 content-start">
              <li className="col-span-1 truncate flex items-start">
                <p className="inline-block pr-2 font-semibold">Provider:</p>
                <p className="inline-block">{layer.source.displayName}</p>
              </li>
              <li className="col-span-1 truncate flex items-start">
                <p className="inline-block pr-2 font-semibold">Source:</p>
                <a
                  className="truncate text-blue-400 visited:text-purple-600"
                  href={layer.source.infoUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {layer.source.infoUrl}
                </a>
              </li>
              <li className="col-span-1 truncate flex items-start">
                <p className="inline-block pr-2 font-semibold">Recency:</p>
                <p className="inline-block">
                  {layer.source.service === ServiceType.ESRI
                    ? 'Real time'
                    : 'Pre-harvested'}
                </p>
              </li>
            </ul>
          </Popover.Button>
        </Popover.Panel>
      </Popover>
    </div>
  )
}

export default LeafNodeDetailButton
