import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from 'src/components/catalyst/dialog'
import { Form, SubmitHandler } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'
import { useAuth } from 'src/auth'
import { Button } from 'src/components/catalyst/button'
import Input from 'src/components/catalyst/rw/Input'
import { PencilIcon } from '@heroicons/react/24/outline'
import { UPDATE_USER_MUTATION } from 'src/queries/users'

export default function UserInput({ label, identifier, setIsOpen, open }) {
  const { currentUser } = useAuth()

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => {
      toast.success('User info updated')
      setIsOpen(false)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  type FormFields = {
    inputField: string
  }

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    await updateUser({
      variables: {
        input: {
          id: currentUser.id,
          email: currentUser.email,
          [identifier]: data.inputField,
        },
      },
    })
    setIsOpen(false)
  }

  return (
    <>
      <PencilIcon
        className="h-6 w-6 cursor-pointer"
        color="#316328"
        onClick={() => setIsOpen(true)}
      />

      <Dialog open={open} onClose={() => setIsOpen(false)}>
        <Form onSubmit={onSubmit}>
          <div className=" ">
            <DialogTitle className="text-base font-semibold  text-gray-900">
              {label}
            </DialogTitle>
            <DialogDescription>
              Your info will be updated upon submission
            </DialogDescription>
            <DialogBody>
              <Input
                name="inputField"
                label={identifier === 'fullName' ? 'Full Name' : 'Title'}
                defaultValue={
                  identifier === 'fullName'
                    ? (currentUser.fullName as string)
                    : (currentUser.title as string)
                }
              />
            </DialogBody>
            <DialogActions>
              <Button plain onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </DialogActions>
          </div>
        </Form>
      </Dialog>
    </>
  )
}
