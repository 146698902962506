export const PROJECT_BY_ORGANIZATION_QUERY = gql`
  query getProjects($organizationId: Int!) {
    projects: projectsByOrganization(organizationId: $organizationId) {
      name
      id
    }
  }
`

export const PROJECT_BY_ID_QUERY = gql`
  query getProjectById($id: Int!) {
    project: project(id: $id) {
      id
      name
    }
  }
`
