import { useMutation } from '@apollo/client'
import { toast } from '@redwoodjs/web/dist/toast'
import { useAuth } from 'src/auth'
import { Form } from '@redwoodjs/forms'
import { navigate } from '@redwoodjs/router'
import { Button } from 'src/components/catalyst/button'
import { SubmitHandler } from '@redwoodjs/forms'
import {
  Field,
  FieldGroup,
  Fieldset,
  Legend,
} from 'src/components/catalyst/fieldset'
import { states } from './states'
import Input from 'src/components/catalyst/rw/Input'
import Select from 'src/components/catalyst/rw/Select'
import { UPDATE_USER_MUTATION } from 'src/queries/users'

const CREATE_ORGANIZATION_MUTATION = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
    }
  }
`

export const OnboardingOrganization = () => {
  const { currentUser, reauthenticate } = useAuth()

  const [createOrganization] = useMutation(CREATE_ORGANIZATION_MUTATION, {
    onCompleted: () => {
      toast.success('Organization created')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => {
      reauthenticate()
      navigate('/onboardingSeats')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  type FormFields = {
    name: string
    address: string
    city: string
    state: string
    industry: string
    zipcode: string
  }

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    const newOrganizationInput = {
      name: data.name,
      createdById: currentUser.id,
      address: data.address,
      city: data.city,
      state: data.state,
      industry: data.industry,
      zipCode: data.zipcode,
    }

    await createOrganization({
      variables: { input: newOrganizationInput },
    })

    await updateUser({
      variables: {
        input: {
          id: currentUser.id,
          completedOnboarding: true,
        },
      },
    })
  }

  const transformedStates = states.map((state, i) => ({
    id: i,
    name: state.name,
    value: state.abbreviation,
  }))

  return (
    <div className="w-full flex h-full justify-center items-center">
      <div className="w-lg-form-width flex font-roboto flex-col border-2 px-16 pt-4 pb-12 items-center border-border rounded-xl">
        <span className=" text-black dark:text-white">
          <img
            src="/silo-logo.svg"
            alt="silo logo"
            className="w-md-image text-black dark:text-white"
          />
        </span>

        <Form
          action="/orders"
          method="POST"
          className=" flex-col gap-4 w-full"
          onSubmit={onSubmit}
        >
          <Fieldset className="flex flex-col ">
            <Legend className=" text-xl text-center">
              Create Organization
            </Legend>

            <FieldGroup className="flex flex-col gap-2">
              <Field>
                <Input
                  name="name"
                  label="Name"
                  className="border-1 border-border"
                />
              </Field>
              <Field>
                <Input
                  name="address"
                  label="Address"
                  className="border-1 border-border"
                />
              </Field>
              <Field>
                <Input
                  name="city"
                  label="City"
                  className="border-1 border-border"
                />
              </Field>
            </FieldGroup>
            <FieldGroup className="flex items-center w-full gap-4">
              <Field className="w-[50%] flex flex-col justify-end mt-2">
                <Select name="state" options={transformedStates} />
              </Field>
              <Field className="w-[50%] flex flex-col justify-end">
                <Input
                  name="zipcode"
                  label="Zipcode"
                  className="border-1 border-border"
                />
              </Field>
            </FieldGroup>
            <FieldGroup>
              <Field>
                <Input
                  name="industry"
                  label="Industry"
                  className="border-1 border-border"
                />
              </Field>
            </FieldGroup>
          </Fieldset>
          <Button color="primary" className="w-full mt-8 " type="submit">
            Save
          </Button>
        </Form>
      </div>
    </div>
  )
}
