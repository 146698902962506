import ProfileImage from './me.jpg'

export const users = [
  {
    name: 'Kyle',
    email: 'kylem147@gmail.com',
    avatar: ProfileImage,
    role: 'Engineer',
  },
  {
    name: 'Andrew',
    email: 'andrewboylecodes@gmail.com',
    avatar: ProfileImage,
    role: 'Engineer',
  },
  {
    name: 'Matthew',
    email: 'matthew@gmail.com',
    avatar: ProfileImage,
    role: 'Engineer',
  },
  {
    name: 'Ryan',
    email: 'salishgeo@gmail.com',
    avatar: ProfileImage,
    role: 'Owner',
  },
  {
    name: 'Kent',
    email: 'kent@gmail.com',
    avatar: ProfileImage,
    role: 'Engineer',
  },
]

export const mockLineTypes = [
  {
    id: 1,
    name: 'line 1',
    color: '#eb4034',
    lineWidth: 1,
    lineCap: 'butt',
  },
  {
    id: 2,

    name: 'line 2',
    color: '#4334eb',
    lineWidth: 2,
    lineCap: 'round',
  },
  {
    id: 3,

    name: 'line 3',
    color: '#eb349e',
    lineWidth: 3,
    lineCap: 'butt',
  },
  {
    id: 4,

    name: 'line 4',
    color: '#1ce84b',
    lineWidth: 4,
    lineCap: 'square',
  },
]

export const dropdownItems = [
  {
    id: 1,
    name: 'Dropdown Item 1',
  },
  {
    id: 2,
    name: 'Dropdown Item 2',
  },
  {
    id: 3,
    name: 'Dropdown Item 3',
  },
  {
    id: 4,
    name: 'Dropdown Item 4',
  },
  {
    id: 5,
    name: 'Dropdown Item 5',
  },
]

export const ListboxItems = [
  {
    id: 1,
    name: 'Active',
  },
  {
    id: 2,
    name: 'Paused',
  },
  {
    id: 3,
    name: 'Delayed',
  },
  {
    id: 4,
    name: 'Canceled',
  },
]
