export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      email
      fullName
      title
      completedOnboarding
      organizationId
      roleId
    }
  }
`
