export const colorOptions = [
  {
    id: 1,
    hex: '#fffb00',
  },
  {
    id: 2,

    hex: '#50d71e',
  },
  {
    id: 3,

    hex: '#008bff',
  },
  {
    id: 4,

    hex: '#00ff2a',
  },
  {
    id: 5,

    hex: '#9000ff',
  },
]
