import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/auth'

import FeatureForm from '../FeatureForm/FeatureForm'
import {
  setDrawingData,
  setFlyoutConfig,
  setLastCreatedFeature,
  setTileCacheBuster,
} from 'src/components/Map/mapSlice'
import { convertToWKT } from 'src/lib/mapUtils'
import { RootState } from 'src/store'

import type { CreateFeatureInput } from 'types/graphql'
import { FeatureType } from 'src/types/features'
import { getFeatureTypeLabel } from 'src/lib/featureUtils'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { getFeatureFromCollection } from 'src/lib/geoJsonUtils'

const CREATE_FEATURE_MUTATION = gql`
  mutation CreateFeatureMutation($input: CreateFeatureInput!) {
    feature: createFeature(input: $input) {
      id
      createdById
      type
      description
      projectId
      tagId
      organizationId
      renderConfigId
      featureCollection
    }
  }
`

type CreateFeatureProps = {
  onClose: () => void
  featureType: FeatureType
}

const CreateFeature = ({ onClose }: CreateFeatureProps) => {
  const { currentUser } = useAuth()
  const drawingData = useSelector((state: RootState) => state.map.drawingData)
  const flyoutConfig = useSelector((state: RootState) => state.map.flyoutConfig)
  const dispatch = useDispatch()
  const [createFeature, { loading, error }] = useMutation(
    CREATE_FEATURE_MUTATION,
    {
      onCompleted: ({ feature }) => {
        toast.success(
          `${getFeatureTypeLabel(flyoutConfig.featureType)} created`
        )
        dispatch(setFlyoutConfig({ component: '' }))
        dispatch(setDrawingData(null))
        dispatch(setLastCreatedFeature(feature))
        dispatch(setTileCacheBuster())
      },
      onError: (error) => {
        toast.error(error.message)
      },
    }
  )
  const onSave = (input: CreateFeatureInput) => {
    const feature = getFeatureFromCollection(drawingData)
    const wktGeom = convertToWKT(feature)
    const { description, projectId, tagId, renderConfigId } = input
    createFeature({
      variables: {
        input: {
          description,
          projectId,
          tagId,
          renderConfigId,
          createdById: currentUser.id,
          organizationId: currentUser.organizationId,
          geometryWkt: wktGeom,
          type: flyoutConfig.featureType,
          featureCollection: drawingData,
        },
      },
    })
  }

  return (
    <div>
      <header className="flex px-4 py-[.5rem] border-b border-gray-300 bg-gray-100 justify-between items-center">
        <h2 className="py-[.25rem] text-md font-semibold text-primary">
          {`Create ${getFeatureTypeLabel(flyoutConfig.featureType)}`}
        </h2>
        <button
          onClick={() => {
            dispatch(setDrawingData(null))
            onClose()
          }}
        >
          <XMarkIcon className="h-5 w-5 hover:text-primary" />
        </button>
      </header>
      <div className="p-4">
        <FeatureForm
          onSave={onSave}
          loading={loading}
          creationError={error}
          featureType={flyoutConfig.featureType}
        />
      </div>
    </div>
  )
}

export default CreateFeature
