import { Field, Label } from 'src/components/catalyst/fieldset'
import { Input } from 'src/components/catalyst/input'

const InputComponent = () => {
  return (
    <Field className="font-roboto">
      <Label className="text-lg ">Input Label</Label>
      <Input
        name="Input"
        className="border-1 border-border w-form-control"
        placeholder="Input Placeholder"
      />
    </Field>
  )
}
export default InputComponent
