import { Text } from 'src/components/catalyst/text'
import GeoForm from './GeoForm'
import OnboardingForm from './OnboardingForm'
import DialogComponent from './DialogComponent'

import Buttons from './Buttons'
import InputComponent from './InputComponent'
import DropdownComponent from './DropdownComponent'
import DropdownWithIcons from './DropdownWithIcons'
import SwitchComponent from './SwitchComponent'
import RadioButtonComponent from './RadioButtonComponent'
import CheckboxComponent from './CheckboxComponent'
import TextAreaComponent from './TextAreaComponent'
import { Listbox } from 'src/components/catalyst/listbox'
import AlertComponent from './AlertComponent'
import ListboxWithIcons from './ListboxWithIcons'
import TableComponent from './TableComponent'
import { mockLineTypes, users } from './mockData'
import ComplexTableComponent from './ComplexTable'
import BadgeComponent from './BadgeComponent'
import { LinkComponent } from './LinkComponent'
import DarkModeToggle from './DarkModeToggle'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { Heading } from 'src/components/catalyst/heading'

const StyleGuide = () => {
  return (
    <div className="w-full py-12 flex flex-col  gap-32 justify-center items-center dark:bg-dark">
      <div>
        <DarkModeToggle />
      </div>
      <div className="flex flex-col gap-8 items-center">
        <Heading className="font-roboto !text-2xl">Heading</Heading>
        <Text className="font-roboto !text-2xl">Text</Text>

        <div className="flex  gap-8 items-center">
          <Text className="font-roboto !text-2xl">Roboto</Text>
          <Text className="font-monsterrat !text-2xl">Monsterrat</Text>
        </div>
      </div>
      <div className="flex flex-col gap-8 items-center">
        <Text>Badge and Link</Text>
        <BadgeComponent />
        <LinkComponent href={'/'} />
      </div>

      <Buttons />
      <div className="flex flex-col gap-8 items-center">
        <Text className="font-roboto !text-2xl">Popups</Text>

        <div className="flex  gap-8 items-center">
          <DialogComponent />
          <AlertComponent />
        </div>
      </div>

      <div className="flex flex-col gap-8 px-12 items-center w-full">
        <Text className="!text-2xl  font-roboto">Form Controls</Text>
        <div className="grid grid-cols-3 grid-rows-3 gap-4 w-full">
          <div className="flex justify-center items-center">
            <InputComponent />
          </div>
          <div className="flex justify-center items-center">
            <DropdownComponent label={'Dropdown Label'}>
              <PlusCircleIcon color="black" className="h-icon" />
            </DropdownComponent>
          </div>
          <div className="flex justify-center items-center">
            <DropdownWithIcons />
          </div>
          <div className="flex justify-center items-center">
            <SwitchComponent />
          </div>
          <div className="flex justify-center items-center">
            <RadioButtonComponent />
          </div>
          <div className="flex justify-center items-center">
            <CheckboxComponent />
          </div>
          <div className="flex justify-center items-center">
            <TextAreaComponent />
          </div>
          <div className="flex justify-center items-center">
            <Listbox name="listboxitems" />
          </div>
          <div className="flex justify-center items-center">
            <ListboxWithIcons />
          </div>
        </div>
      </div>
      <div className="w-full px-16 flex flex-col gap-8 justify-center">
        <Text className="!text-2xl font-bold font-roboto w-full">
          Simple Table
        </Text>

        <TableComponent users={users} />
      </div>
      <div className="w-full px-16 flex flex-col gap-8 justify-center">
        <Text className="!text-2xl font-bold font-roboto w-full">
          Complex Table
        </Text>

        <ComplexTableComponent types={mockLineTypes} />
      </div>

      <div className="flex flex-col gap-12 items-center">
        <Text className="!text-2xl font-bold font-roboto">Form Examples</Text>
        <div className="flex justify-center items-center gap-12">
          <GeoForm />
          <OnboardingForm />
        </div>
      </div>
    </div>
  )
}

export default StyleGuide
