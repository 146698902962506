import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from 'src/components/catalyst/alert'
import { Button } from 'src/components/catalyst/button'
import { useState } from 'react'

const AlertComponent = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        type="button"
        color="secondary"
        className="w-button"
        onClick={() => setIsOpen(true)}
      >
        Alert
      </Button>
      <Alert open={isOpen} onClose={setIsOpen} className="font-roboto">
        <AlertTitle>Are you sure you want to refund this payment?</AlertTitle>
        <AlertDescription>
          The refund will be reflected in the customer’s bank account 2 to 3
          business days after processing.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button color="accent" onClick={() => setIsOpen(false)}>
            Refund
          </Button>
        </AlertActions>
      </Alert>
    </>
  )
}

export default AlertComponent
