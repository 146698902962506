import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'
import { useAuth } from 'src/auth'
import { Button } from 'src/components/catalyst/button'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from 'src/components/catalyst/dialog'
import { Form } from '@redwoodjs/forms'
import Input from 'src/components/catalyst/rw/Input'

const CREATE_PROJECT_MUTATION = gql`
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      name
    }
  }
`

const CreateProjectDialog = ({ isOpen, setIsOpen }) => {
  const { currentUser } = useAuth()
  const [createProjectMutation] = useMutation(CREATE_PROJECT_MUTATION, {
    update: (cache, { data: { createProject } }) => {
      cache.modify({
        fields: {
          projectsByOrganization(existingProjects = []) {
            const newProjectRef = cache.writeFragment({
              data: createProject,
              fragment: gql`
                fragment NewProject on Project {
                  id
                  name
                }
              `,
            })
            return [...existingProjects, newProjectRef]
          },
        },
      })
    },
    onCompleted: () => {
      toast.success('Your project has been created')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const handleSubmit = async ({ name }) => {
    await createProjectMutation({
      variables: {
        input: {
          name,
          organizationId: currentUser.organizationId,
        },
      },
    })
    setIsOpen(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
    >
      <Form onSubmit={handleSubmit}>
        <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
          Create New Project
        </DialogTitle>
        <DialogBody>
          <Input
            name="name"
            label="Project Name"
            placeholderText={`New project's name`}
            validation={{ required: true }}
          />
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Create
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default CreateProjectDialog
