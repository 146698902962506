import {
  Field,
  ErrorMessage,
  Label,
  Description,
} from 'src/components/catalyst/fieldset'
import { Select as CatalystSelect } from 'src/components/catalyst/select'
import {
  type FieldProps,
  type LabelProps,
  type SelectProps,
} from '@headlessui/react'
import { Controller, RegisterOptions, useErrorStyles } from '@redwoodjs/forms'

const PLACEHOLDER = '__PLACEHOLDER__'
interface Option {
  id: number
  name: string
  value: string
}

interface Props {
  name: string
  label?: string
  defaultValue?: string | number
  placeholderText?: string
  description?: string
  options?: Option[]
  className?: string
  style?: React.StyleHTMLAttributes<HTMLSelectElement>
  errorClassName?: string
  fieldErrorClassName?: string
  labelProps?: { className?: string } & LabelProps
  fieldProps?: FieldProps
  selectProps?: SelectProps
  validation?: RegisterOptions
  loading?: boolean
}

const Select = (props: Props) => {
  const {
    name,
    label = `${props.name.charAt(0).toUpperCase()}${props.name.slice(1)}`,
    defaultValue = PLACEHOLDER,
    placeholderText = 'Select an option',
    description,
    options,
    className = 'select',
    style = {},
    errorClassName = 'error border-red-500',
    labelProps,
    fieldProps,
    selectProps,
    validation = {},
    loading,
  } = props

  const { className: componentClassName, style: componentStyle } =
    useErrorStyles({
      className: className,
      errorClassName: errorClassName,
      name: name,
    })

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={{
        validate: {
          matchesInitialValue: (value) => {
            if (!validation.required) {
              return true
            } else {
              const message =
                typeof validation.required === 'string'
                  ? validation.required
                  : placeholderText
              return value !== PLACEHOLDER || message
            }
          },
        },
        ...validation,
      }}
      render={({
        field: { onChange, value, name, ref },
        formState: { errors },
      }) => (
        <>
          <Field {...fieldProps}>
            <Label {...labelProps}>{label}</Label>
            {description && <Description>{description}</Description>}
            <CatalystSelect
              name={name}
              value={value}
              onChange={onChange}
              className={componentClassName}
              style={{ ...componentStyle, ...style }}
              ref={ref}
              {...selectProps}
            >
              <option value={PLACEHOLDER} disabled>
                {placeholderText}
              </option>
              {loading ? (
                <option value={PLACEHOLDER} disabled>
                  Loading...
                </option>
              ) : !options ? (
                <option value={PLACEHOLDER} disabled>
                  No options available
                </option>
              ) : (
                options?.map((option) => (
                  <option key={option.id} value={option.value}>
                    {option.name}
                  </option>
                ))
              )}
            </CatalystSelect>
            {name in errors && (
              <ErrorMessage>{`${
                errors[name].message || `${name} is required`
              }`}</ErrorMessage>
            )}
          </Field>
        </>
      )}
    />
  )
}

export default Select
