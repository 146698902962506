import { useMap } from 'react-map-gl'
import { FeatureCollection } from 'src/components/Map/FeatureServiceManager'
import {
  Geometry,
  Feature,
  Point,
  LineString,
  Polygon,
  GeoJsonProperties,
} from 'geojson'

export type BoundingBox = {
  xmin: number
  ymin: number
  xmax: number
  ymax: number
}

export const getBoundingBox = () => {
  const { siloMap } = useMap()
  if (siloMap) {
    const bounds = siloMap.getBounds()
    const sw = bounds.getSouthWest()
    const ne = bounds.getNorthEast()
    const boundingCoordinates: BoundingBox = {
      xmin: sw.lng,
      ymin: sw.lat,
      xmax: ne.lng,
      ymax: ne.lat,
    }

    return boundingCoordinates
  }
  return null
}

interface ConvertToWKT {
  (feature: Feature<Geometry, GeoJsonProperties>): string
}

export const convertToWKT: ConvertToWKT = (
  feature: Feature<Geometry, GeoJsonProperties>
): string => {
  if (feature.geometry === null) {
    throw new Error('Geometry is null')
  }

  const { type } = feature.geometry

  let wkt = `${type.toUpperCase()}(`

  switch (type) {
    case 'Point':
      wkt += (feature.geometry as Point).coordinates.join(' ')
      break
    case 'LineString':
      wkt += (feature.geometry as LineString).coordinates
        .map((coord) => coord.join(' '))
        .join(', ')
      break
    case 'Polygon':
      wkt += (feature.geometry as Polygon).coordinates
        .map((ring) => `(${ring.map((coord) => coord.join(' ')).join(', ')})`)
        .join(', ')
      break
    default:
      throw new Error(`Unsupported geometry type: ${type}`)
  }

  wkt += ')'
  return wkt
}

export function getCurrentTimestamp() {
  const now = new Date()
  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'America/Los_Angeles', // For Pacific Standard Time (PST)
  }

  // Assuming the system's locale is 'en-US'
  const dateFormatter = new Intl.DateTimeFormat('en-US', options)
  const formattedDate = dateFormatter.format(now)
  const [month, day, year] = formattedDate
    .split('/')
    .map((part) => part.padStart(2, '0'))
  const [hour, minute] = formattedDate.split(', ')[1].split(':')

  // To dynamically obtain the timezone abbreviation
  const timeZoneName = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'short',
  })
    .formatToParts(now)
    .find((part) => part.type === 'timeZoneName')?.value

  // Constructing the timestamp string
  const timestamp = `${year}${month}${day}_${hour}${minute}${
    timeZoneName ? timeZoneName.toLowerCase() : ''
  }`

  return timestamp
}

export function nearestKey(
  value: number,
  map: Map<number, FeatureCollection>
): number | undefined {
  if (map.size === 0) {
    return undefined
  }

  let nearestKey: number | undefined
  let minDifference = Infinity

  for (const key of map.keys()) {
    const difference = Math.abs(value - key)

    if (difference < minDifference) {
      nearestKey = key
      minDifference = difference
    } else if (nearestKey && difference === minDifference && key > nearestKey) {
      nearestKey = key
    }
  }

  return nearestKey
}
