import type { UpdateFeatureInput } from 'types/graphql'
import type { CellFailureProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import FeatureForm from '../FeatureForm/FeatureForm'
import {
  setDrawingData,
  setFeatureToUpdate,
  setFlyoutConfig,
  setLastEditedFeature,
  setTileCacheBuster,
} from 'src/components/Map/mapSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { useAuth } from 'src/auth'
import { FeatureType } from 'src/types/features'
import { getFeatureTypeLabel } from 'src/lib/featureUtils'
import { XMarkIcon } from '@heroicons/react/24/outline'

const UPDATE_FEATURE_MUTATION = gql`
  mutation UpdateFeatureMutation(
    $geoserviceId: Int!
    $metaId: Int!
    $input: UpdateFeatureInput!
  ) {
    feature: updateFeature(
      geoserviceId: $geoserviceId
      metaId: $metaId
      input: $input
    ) {
      id
      createdById
      type
      description
      projectId
      tagId
      organizationId
      renderConfigId
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div className="rw-cell-error">{error?.message}</div>
)

type EditFeatureProps = {
  featureType: FeatureType
  onClose: () => void
}
const EditFeature = ({ onClose }: EditFeatureProps) => {
  const { currentUser } = useAuth()
  const flyoutConfig = useSelector((state: RootState) => state.map.flyoutConfig)
  const featureToUpdate = useSelector(
    (state: RootState) => state.map.featureToUpdate
  )
  const dispatch = useDispatch()
  const [updateFeature, { loading, error }] = useMutation(
    UPDATE_FEATURE_MUTATION,
    {
      onCompleted: ({ feature }) => {
        toast.success(`${flyoutConfig.featureType} updated`)
        dispatch(setFlyoutConfig({ component: '' }))
        dispatch(setFeatureToUpdate(null))
        dispatch(setLastEditedFeature(feature))
        dispatch(setTileCacheBuster())
      },
      onError: (error) => {
        toast.error(error.message)
      },
    }
  )

  const onUpdate = (input: UpdateFeatureInput) => {
    const { description, projectId, tagId, renderConfigId } = input
    updateFeature({
      variables: {
        geoserviceId: featureToUpdate.properties.id,
        metaId: featureToUpdate.properties.meta_id,
        input: {
          description,
          organizationId: currentUser.organizationId,
          projectId,
          renderConfigId,
          tagId,
        },
      },
    })
  }

  return (
    <div>
      <header className="flex px-4 py-[.5rem] border-b border-gray-300 bg-gray-100 justify-between items-center">
        <h2 className="py-[.25rem] text-md font-semibold text-primary">
          {`Edit ${getFeatureTypeLabel(flyoutConfig.featureType)}`}
        </h2>
        <button
          onClick={() => {
            dispatch(setDrawingData(null))
            onClose()
          }}
        >
          <XMarkIcon className="h-5 w-5 hover:text-primary" />
        </button>
      </header>
      <div className="p-4">
        <FeatureForm
          featureType={flyoutConfig.featureType}
          onUpdate={onUpdate}
          loading={loading}
          creationError={error}
        />
      </div>
    </div>
  )
}

export default EditFeature
