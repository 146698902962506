import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from 'src/components/catalyst/dropdown'
import { Field, Label } from 'src/components/catalyst/fieldset'
import { Text } from 'src/components/catalyst/text'

import {
  ChevronDownIcon,
  Cog8ToothIcon,
  InformationCircleIcon,
  MoonIcon,
  UserIcon,
} from '@heroicons/react/24/outline'

const DropdownWithIcons = () => {
  return (
    <Field className="font-roboto flex flex-col">
      <Label>Dropdown With Icons</Label>
      <Dropdown>
        <DropdownButton
          color="white"
          className="w-form-control h-form-control mt-dropdown-label dark:bg-white/5"
        >
          <Text className="w-full flex justify-start font-normal text-muted">
            Dropdown Button
          </Text>
          <ChevronDownIcon className="max-h-[15px]" color="black" />
        </DropdownButton>
        <DropdownMenu className="w-full">
          <DropdownItem className="flex gap-2">
            <MoonIcon className="h-icon" />
            Dark Mode
          </DropdownItem>
          <DropdownItem className="flex gap-2">
            <Cog8ToothIcon className="h-icon" />
            Settings
          </DropdownItem>
          <DropdownItem className="flex gap-2">
            <InformationCircleIcon className="h-icon" />
            Info
          </DropdownItem>
          <DropdownItem className="flex gap-2">
            <UserIcon className="h-icon" />
            Profile
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Field>
  )
}

export default DropdownWithIcons
