import { DataInteractive as HeadlessDataInteractive } from '@headlessui/react'
import React from 'react'

interface LinkProps extends React.ComponentPropsWithoutRef<'a'> {
  href: string
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function Link(
    { children, href, ...props }: LinkProps,
    ref: React.ForwardedRef<HTMLAnchorElement>
  ) {
    return (
      <HeadlessDataInteractive>
        <a href={href} {...props} ref={ref}>
          {children || 'Default Link Text'}
        </a>
      </HeadlessDataInteractive>
    )
  }
)
