import { Layer, LayerScope } from '../LayerSelector/LayerTypes'

type ScopeBadgeProps = {
  layer: Layer
  active: boolean
}

const getBadgeColor = (scope: LayerScope) => {
  switch (scope) {
    case LayerScope.FEDERAL:
      return 'fill-red-500'
    case LayerScope.STATE:
      return 'fill-blue-500'
    case LayerScope.COUNTY:
      return 'fill-yellow-500'
    case LayerScope.CITY:
      return 'fill-green-500'
    default:
      return 'fill-gray-500'
  }
}

const scopeToText = (scope: LayerScope) => {
  switch (scope) {
    case LayerScope.FEDERAL:
      return 'N'
    case LayerScope.STATE:
      return 'S'
    case LayerScope.COUNTY:
      return 'C'
    case LayerScope.CITY:
      return 'M'
    default:
      return 'Unknown'
  }
}

const ScopeBadge = ({ layer, active = true }: ScopeBadgeProps) => {
  const { scope } = layer
  return (
    <div className="relative">
      <span
        className={`group/span inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-xs font-medium ${
          active ? 'text-gray-900 ring-gray-300' : 'text-gray-300 ring-gray-200'
        } ring-1 ring-inset select-none `}
      >
        <svg
          className={`h-1.5 w-1.5 ${
            active ? getBadgeColor(scope) : 'fill-gray-300'
          }`}
          viewBox="0 0 6 6"
          aria-hidden="true"
        >
          <circle cx={3} cy={3} r={3} />
        </svg>
        <p>
          {scope === LayerScope.FEDERAL ? (
            <span>{`${scopeToText(scope)}`}</span>
          ) : (
            <>
              <span>{`${scopeToText(scope)} - ${
                layer.source.displayName
              }`}</span>
            </>
          )}
        </p>
      </span>
    </div>
  )
}

export default ScopeBadge
