import React, { useState } from 'react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useAuth } from 'src/auth'
import { UserRoles } from 'src/roles'
import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from 'src/components/catalyst/alert'
import { Button } from 'src/components/catalyst/button'
import { Invitation, User } from 'types/graphql'

interface TeamMemberCardProps {
  item: User | Invitation
  allowedRoles: UserRoles[]
  handleDelete: (id: number, userType: string) => Promise<void>
}

const displayName = (fullName: string, email: string) => {
  if (fullName) {
    return fullName
  }
  if (!email) {
    return 'User'
  }
  const atIndex = email.indexOf('@')
  if (atIndex !== -1) {
    return email.substring(0, atIndex)
  }
  return email
}

function isUser(item: User | Invitation): item is User {
  return (item as User).email !== undefined
}

export const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
  item,
  allowedRoles,
  handleDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { currentUser } = useAuth()

  const canDelete =
    allowedRoles?.includes(currentUser.roleId as number) &&
    handleDelete &&
    (!isUser(item) || (isUser(item) && item.roleId !== 1))

  return (
    <li
      key={item.id}
      className="rounded-md py-5 w-full px-4 flex flex-col sm:flex-row justify-between items-start sm:items-center"
    >
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0 min-w-0 gap-x-4 w-full">
        <div className="flex-grow">
          <p className="font-medium text-gray-900">
            {isUser(item)
              ? displayName(item.fullName, item.email)
              : displayName(item.user.fullName, item.user.email)}
          </p>
        </div>
        <div className="flex-1 min-w-0 sm:text-right xs:text-left">
          <p className="text-base font-semibold leading-6 text-gray-900 truncate">
            {isUser(item)
              ? item.title ?? 'Pending Onboard'
              : 'Pending Acceptance'}
          </p>
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
            {isUser(item) ? item.email : item.user.email}
          </p>
        </div>
        <div className="flex-shrink-0 hidden sm:block">
          {canDelete && (
            <Popover className="relative">
              <PopoverButton className="-mr-4 px-1 pt-1">
                <EllipsisVerticalIcon className="h-5 w-5 text-gray-500" />
              </PopoverButton>
              <PopoverPanel
                onClick={(e) => e.stopPropagation()}
                className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10"
              >
                <div>
                  <Button
                    className="w-full"
                    color="red"
                    onClick={() => setIsOpen(true)}
                  >
                    {isUser(item) ? 'Remove User' : 'Revoke Invitation'}
                  </Button>
                  <Alert
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    size="md"
                  >
                    <AlertTitle>Are you sure?</AlertTitle>
                    <AlertDescription>
                      {isUser(item)
                        ? "The user will lose access to all of this organization's data."
                        : 'The invitation will be revoked, and the user will no longer have the option to join this organization.'}
                    </AlertDescription>
                    <AlertActions>
                      <Button
                        className="w-full"
                        color="red"
                        onClick={() => {
                          handleDelete(
                            item.id,
                            isUser(item) ? 'MEMBER' : 'INVITEE'
                          )
                          setIsOpen(false)
                        }}
                      >
                        Continue
                      </Button>
                      <Button
                        className="w-full"
                        color="primary"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </Button>
                    </AlertActions>
                  </Alert>
                </div>
              </PopoverPanel>
            </Popover>
          )}
        </div>
      </div>
    </li>
  )
}
