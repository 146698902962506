import * as Sentry from '@sentry/react'
// If browser tracing is needed, install react-router-dom and configure the browser tracing integration
// import { useEffect } from 'react'
// import {
//   createRoutesFromChildren,
//   matchRoutes,
//   useLocation,
//   useNavigationType,
// } from "react-router-dom";

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_RW_DSN,
    // If browser tracing is needed
    integrations: [
      // Sentry.reactRouterV6BrowserTracingIntegration({
      //   useEffect,
      //   useLocation,
      //   useNavigationType,
      //   createRoutesFromChildren,
      //   matchRoutes,
      // }),
    ],
    tracesSampleRate: 0.1, // Adjust this value based on the amount of tracing needed for our app (0.0 - 1.0)
  })
}

export default Sentry
