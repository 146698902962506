import { FC, useState } from 'react'
import { useMutation, useApolloClient, useQuery } from '@apollo/client'
import { toast } from '@redwoodjs/web/dist/toast'
import { useAuth } from 'src/auth'
import { Form, SubmitHandler } from '@redwoodjs/forms'
import { Button } from 'src/components/catalyst/button'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from 'src/components/catalyst/dialog'
import { FeatureType } from 'src/types/features'
import { RenderConfig } from 'types/graphql'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import Input from 'src/components/catalyst/rw/Input'
import {
  CREATE_RENDER_CONFIG_MUTATION,
  UPDATE_RENDER_CONFIG_MUTATION,
  DELETE_RENDER_CONFIG_MUTATION,
  RENDER_CONFIGS_BY_ORG_QUERY,
  RENDER_CONFIG_FEATURE_COUNT,
} from 'src/queries/renderConfigs'
import Select from 'src/components/catalyst/rw/Select'
import ColorInput from 'src/components/catalyst/rw/ColorInput'

type LineTypeFormProps = {
  defaultOpen?: boolean
  mode?: 'create' | 'edit'
  config?: RenderConfig
}

export const LineTypeForm: FC<LineTypeFormProps> = ({
  mode = 'create',
  config = null,
  defaultOpen = false,
}) => {
  const [open, setOpen] = useState(defaultOpen)
  const editing = mode === 'edit'
  const init = {
    name: mode === 'create' ? '' : config.name,
    color: mode === 'create' ? '#000000' : config.paint['line-color'],
    width: mode === 'create' ? '1' : config.paint['line-width'],
    cap: mode === 'create' ? 'round' : config.layout['line-cap'],
  }

  const { currentUser } = useAuth()

  const client = useApolloClient()
  const refetchRenderConfigs = () =>
    client.query({
      query: RENDER_CONFIGS_BY_ORG_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          organizationId: currentUser?.organizationId,
        },
      },
    })

  const { data: featureCountData } = useQuery(RENDER_CONFIG_FEATURE_COUNT, {
    variables: { id: config?.id },
    skip: !config?.id,
  })

  const [createRenderConfig] = useMutation(CREATE_RENDER_CONFIG_MUTATION, {
    onCompleted: () => {
      refetchRenderConfigs()
      toast.success('Line Created')
      setOpen(false)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [updateRenderConfig] = useMutation(UPDATE_RENDER_CONFIG_MUTATION, {
    onCompleted: () => {
      refetchRenderConfigs()
      toast.success('Line Updated')
      setOpen(false)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [deleteRenderConfigMutation] = useMutation(
    DELETE_RENDER_CONFIG_MUTATION,
    {
      onCompleted: () => {
        refetchRenderConfigs()
        toast.success('Line Deleted')
        setOpen(false)
      },
      onError: (error) => {
        toast.error(error.message)
      },
    }
  )

  type FormFields = {
    lineTypeName: string
    lineColor: string
    lineWidth: string
    lineCap: string
  }

  const handleCreateLineType = async (data: FormFields) => {
    await createRenderConfig({
      variables: {
        input: {
          createdById: currentUser.id,
          organizationId: currentUser.organizationId,
          name: data.lineTypeName,
          featureType: FeatureType.line,
          layout: {
            'line-cap': data.lineCap,
          } as mapboxgl.LineLayout,
          paint: {
            'line-color': data.lineColor,
            'line-width': parseInt(data.lineWidth, 10),
          } as mapboxgl.LinePaint,
        },
      },
    })
  }

  const handleUpdateLineType = async (data: FormFields) => {
    await updateRenderConfig({
      variables: {
        id: config.id,
        input: {
          name: data.lineTypeName,
          featureType: FeatureType.line,
          layout: {
            'line-cap': data.lineCap,
          } as mapboxgl.LineLayout,
          paint: {
            'line-color': data.lineColor,
            'line-width': parseInt(data.lineWidth, 10),
          } as mapboxgl.LinePaint,
        },
      },
    })
  }

  const handleDeleteLineType = async () => {
    await deleteRenderConfigMutation({
      variables: { id: config.id },
    })
  }

  const handleDeleteLineTypeButtonClick = () => {
    if (featureCountData?.renderConfigFeatureCount.featureCount > 0) {
      toast.error('Cannot delete a Line with geometries associated')
    } else {
      handleDeleteLineType()
    }
  }

  const handleSubmit: SubmitHandler<FormFields> = (data) => {
    if (editing) {
      handleUpdateLineType(data)
    } else {
      handleCreateLineType(data)
    }
    setOpen(false)
  }

  return (
    <>
      <button
        className="p-2 rounded-md text-primary hover:text-text hover:bg-light"
        onClick={() => setOpen(!open)}
      >
        {editing ? (
          'Edit'
        ) : (
          <div className="flex items-center gap-x-2">
            <PlusCircleIcon className="h-5 w-5" />
            <span>Line Type</span>
          </div>
        )}
      </button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <Form onSubmit={handleSubmit}>
          <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
            {editing ? 'Edit' : 'Create'} Line Type
          </DialogTitle>
          <DialogDescription>
            {editing
              ? 'Update the line type details'
              : 'Enter details for the new line type'}
          </DialogDescription>
          <DialogBody>
            <div className="flex flex-col gap-4 w-full items-start">
              <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col gap-0 w-full">
                  <Input
                    name="lineTypeName"
                    label="Name"
                    defaultValue={init.name}
                  />
                </div>
                <div className="flex flex-row sm:flex-col sm:items-start items-center justify-between w-full">
                  <ColorInput
                    name="lineColor"
                    defaultValue={init.color}
                    label="Line Color"
                    validation={{ required: true }}
                  />
                </div>
                <div className="flex flex-row sm:flex-col sm:items-start items-center justify-between w-full">
                  <div>
                    <Select
                      name="lineCap"
                      label="Line Cap"
                      options={['round', 'butt', 'square'].map((item, i) => ({
                        id: i,
                        name: item,
                        value: item,
                      }))}
                      defaultValue={init.cap}
                    />
                  </div>
                </div>
                <div className="flex flex-row sm:flex-col sm:items-start items-center justify-between w-full">
                  <div>
                    <Input
                      name="lineWidth"
                      label="Width"
                      type="number"
                      placeholder="0-10"
                      defaultValue={init.width}
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogBody>
          <DialogActions>
            <Button plain onClick={() => setOpen(false)}>
              Cancel
            </Button>
            {editing ? (
              <div className="flex flex-row gap-3">
                <Button
                  color="secondary"
                  type="button"
                  onClick={handleDeleteLineTypeButtonClick}
                >
                  Delete Type
                </Button>

                <Button color="primary" type="submit">
                  Update Type
                </Button>
              </div>
            ) : (
              <Button color="primary" type="submit">
                Create Type
              </Button>
            )}
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}
