import { Link } from 'src/components/catalyst/link'
import { Button } from 'src/components/catalyst/button'

export const LinkComponent = ({ href }) => {
  return (
    <>
      <Button plain>
        <Link href={href} className="underline color-primary" color="primary">
          Link
        </Link>
      </Button>
    </>
  )
}
