import Flyout from 'src/components/LayerSelector/Flyout/Flyout'
import UploadIcon from 'src/Icons/UploadIcon'
import LayerIcon from 'src/Icons/LayerIcon'
import SettingsIcon from 'src/Icons/SettingsIcon'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { setFlyoutConfig, setDrawMode } from 'src/components/Map/mapSlice'
import { navigate, routes, useRouteName } from '@redwoodjs/router'
import LayerSelector from 'src/components/LayerSelector/LayerSelector'
import FileUploadComponent from 'src/components/FileUpload/FileUploadComponent'
import DownloadIcon from 'src/Icons/DownloadIcon'
import { FileDownloadComponent } from 'src/components/FileDownload/FileDownloadComponent'
import CreateFeature from 'src/components/Feature/CreateFeature/CreateFeature'
import EditFeature from 'src/components/Feature/EditFeature/EditFeature'

type MapLayoutProps = {
  children?: React.ReactNode
}

const flyoutComponents = {
  Layers: LayerSelector,
  CreateFeature: CreateFeature,
  EditFeature: EditFeature,
  FileUpload: FileUploadComponent,
  FileDownload: FileDownloadComponent,
}

const MapLayout = ({ children }: MapLayoutProps) => {
  const dispatch = useDispatch()
  const { component, featureType } = useSelector(
    (state: RootState) => state.map.flyoutConfig
  )
  const FlyoutComponent = component ? flyoutComponents[component] : null
  const routeName = useRouteName()
  const closeFlyout = () => dispatch(setFlyoutConfig({ component: '' }))

  let navigation = [
    { name: 'Layers', icon: LayerIcon, current: false },
    { name: 'FileUpload', icon: UploadIcon, current: false },
    { name: 'FileDownload', icon: DownloadIcon, current: false },
  ]
  // Hide the settings flyout for the map page
  if (routeName === 'map') {
    navigation = [
      ...navigation,
      { name: 'Settings', icon: SettingsIcon, current: false },
    ]
  }

  const handleItemClick = (itemName: string) => {
    if (itemName === 'Settings') {
      navigate(routes.settings())
    }
    if (component === itemName) {
      closeFlyout()
    } else {
      dispatch(setFlyoutConfig({ component: itemName, featureType }))
      dispatch(
        setDrawMode({ mode: 'simple_select', updatedBy: 'flyout-manager' })
      )
    }
  }

  return (
    <main className="relative h-[100vh]">
      {children}
      <ul
        className={`absolute flex flex-col ${
          routeName === 'map'
            ? 'top-[72px] sm:top-[56px]'
            : 'flyout-top-mobile sm:flyout-top'
        } left-0 w-half h-[30vh] m-3.5 space-y-2`}
      >
        {navigation.map((item) => (
          <li
            key={item.name}
            id={`flyout-item-${item.name.toLowerCase()}`}
            className="m-0 bg-white rounded-lg"
          >
            <button
              onClick={() => handleItemClick(item.name)}
              className={`flex flex-col justify-center items-center group text-gray-700 ${
                item.name === component ? 'bg-secondary text-white' : ''
              } flex gap-x-3 rounded-md w-[35px] h-[35px] sm:w-[40px] sm:h-[40px] text-sm leading-6 font-semibold`}
            >
              <item.icon aria-hidden="true" />
            </button>
          </li>
        ))}
      </ul>
      {FlyoutComponent && (
        <Flyout>
          <FlyoutComponent onClose={closeFlyout} />
        </Flyout>
      )}
    </main>
  )
}

export default MapLayout
