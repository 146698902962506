import type {
  Layer,
  LayerGroup as LayerGroupType,
} from '../LayerSelector/LayerTypes'
import { LayerType } from '../LayerSelector/LayerTypes'
import LayerGroup from '../LayerGroup/LayerGroup'
import LayerLeafNode from '../LayerLeafNode/LayerLeafNode'
import { LayerDataSection } from '../LayerSelector/LayerTypes'

type LayerTreeProps = {
  layerData: Array<Layer | LayerGroupType>
  section: LayerDataSection
}
const LayerTree = ({ layerData, section }: LayerTreeProps) => {
  return (
    <ul className="px-4 py-3">
      {layerData.map((item, i) => {
        if (item.type === LayerType.layerGroup) {
          return (
            <li key={`root-${i}`}>
              <LayerGroup
                showGroupName={true}
                layerGroup={item as LayerGroupType}
                section={section}
              />
            </li>
          )
        } else {
          return (
            <LayerLeafNode
              key={`root-${i}`}
              leafNode={item as Layer}
              section={section}
            />
          )
        }
      })}
    </ul>
  )
}

export default LayerTree
