import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import LayerTree from '../LayerTree/LayerTree'
import SidebarSectionDisclosure from '../SidebarSectionDisclosure/SidebarSectionDisclosure'
import { accumulateActiveLayers } from 'src/lib/layerUtils'

const PublicLayerSelector = () => {
  const publicLayerData = useSelector(
    (state: RootState) => state.map.layerData.public
  )
  const activeLayers = useMemo(
    () => accumulateActiveLayers(publicLayerData),
    [publicLayerData]
  )
  const viewState = useSelector((state: RootState) => state.map.viewState)
  const zoomThreshold = useSelector(
    (state: RootState) => state.map.zoomThreshold
  )
  return (
    <SidebarSectionDisclosure
      label="Public Layers"
      secondaryLabel={
        activeLayers.length
          ? `${activeLayers.length} layer${
              activeLayers.length > 1 ? 's' : ''
            } selected`
          : ''
      }
      content={
        viewState.zoom > zoomThreshold && publicLayerData ? (
          <LayerTree layerData={publicLayerData} section="public" />
        ) : (
          <>
            <div className="flex justify-center items-center w-full h-full">
              <h2 className="p-3 font-italic">
                Zoom to see publicly available layers
              </h2>
            </div>
          </>
        )
      }
    />
  )
}

export default PublicLayerSelector
