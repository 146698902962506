import {
  Description,
  Fieldset,
  Label,
  Legend,
} from 'src/components/catalyst/fieldset'
import { Radio, RadioField, RadioGroup } from 'src/components/catalyst/radio'
import { Text } from 'src/components/catalyst/text'

const RadioButtonComponent = () => {
  return (
    <Fieldset>
      <Legend>Radio Button</Legend>
      <Text>Choose one or the other</Text>
      <RadioGroup name="resale" defaultValue="permit">
        <RadioField>
          <Radio value="permit" color="primary" />
          <Label>This is one</Label>
          <Description>This one determines one thing.</Description>
        </RadioField>
        <RadioField>
          <Radio value="forbid" color="primary" />
          <Label>This is the other</Label>
          <Description>This one determines another thing</Description>
        </RadioField>
      </RadioGroup>
    </Fieldset>
  )
}

export default RadioButtonComponent
