import { FC, useState } from 'react'
import { useMutation, useApolloClient, useQuery } from '@apollo/client'
import { toast } from '@redwoodjs/web/dist/toast'
import { useAuth } from 'src/auth'
import { Form, SubmitHandler } from '@redwoodjs/forms'
import { Button } from 'src/components/catalyst/button'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from 'src/components/catalyst/dialog'
import { FeatureType } from 'src/types/features'
import { RenderConfig } from 'types/graphql'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import Input from 'src/components/catalyst/rw/Input'
import {
  CREATE_RENDER_CONFIG_MUTATION,
  UPDATE_RENDER_CONFIG_MUTATION,
  DELETE_RENDER_CONFIG_MUTATION,
  RENDER_CONFIGS_BY_ORG_QUERY,
  RENDER_CONFIG_FEATURE_COUNT,
} from 'src/queries/renderConfigs'
import ColorInput from 'src/components/catalyst/rw/ColorInput'

type PolygonTypeFormProps = {
  defaultOpen?: boolean
  mode?: 'create' | 'edit'
  config?: RenderConfig
}

export const PolygonTypeForm: FC<PolygonTypeFormProps> = ({
  mode = 'create',
  config = null,
  defaultOpen = false,
}) => {
  const [open, setOpen] = useState(defaultOpen)
  const editing = mode === 'edit'
  const init = {
    name: mode === 'create' ? '' : config.name,
    fillColor: mode === 'create' ? '#000000' : config.paint['fill-color'],
    outlineColor:
      mode === 'create' ? '#000000' : config.paint['fill-outline-color'],
    opacity: mode === 'create' ? '1.0' : config.paint['fill-opacity'],
  }

  const { currentUser } = useAuth()

  const client = useApolloClient()
  const refetchRenderConfigs = () =>
    client.query({
      query: RENDER_CONFIGS_BY_ORG_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          organizationId: currentUser?.organizationId,
        },
      },
    })

  const { data: featureCountData } = useQuery(RENDER_CONFIG_FEATURE_COUNT, {
    variables: { id: config?.id },
    skip: !config?.id,
  })

  const [createRenderConfig] = useMutation(CREATE_RENDER_CONFIG_MUTATION, {
    onCompleted: () => {
      refetchRenderConfigs()
      toast.success('Polygon Created')
      setOpen(false)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [updateRenderConfig] = useMutation(UPDATE_RENDER_CONFIG_MUTATION, {
    onCompleted: () => {
      refetchRenderConfigs()
      toast.success('Polygon Updated')
      setOpen(false)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [deleteRenderConfigMutation] = useMutation(
    DELETE_RENDER_CONFIG_MUTATION,
    {
      onCompleted: () => {
        refetchRenderConfigs()
        toast.success('Polygon Deleted')
        setOpen(false)
      },
      onError: (error) => {
        toast.error(error.message)
      },
    }
  )

  type FormFields = {
    polygonTypeName: string
    fillColor: string
    outlineColor: string
    opacity: string
    lineWidth: string
  }

  const handleCreatePolygonType = async (data: FormFields) => {
    await createRenderConfig({
      variables: {
        input: {
          createdById: currentUser.id,
          organizationId: currentUser.organizationId,
          name: data.polygonTypeName,
          featureType: FeatureType.fill,
          layout: {} as mapboxgl.FillLayout,
          paint: {
            'fill-opacity': parseFloat(data.opacity),
            'fill-color': data.fillColor,
            'fill-outline-color': data.outlineColor,
          } as mapboxgl.FillPaint,
        },
      },
    })
  }

  const handleUpdatePolygonType = async (data: FormFields) => {
    await updateRenderConfig({
      variables: {
        id: config.id,
        input: {
          name: data.polygonTypeName,
          featureType: FeatureType.fill,
          layout: {} as mapboxgl.FillLayout,
          paint: {
            'fill-opacity': parseFloat(data.opacity),
            'fill-color': data.fillColor,
            'fill-outline-color': data.outlineColor,
          } as mapboxgl.FillPaint,
        },
      },
    })
  }

  const handleDeletePolygonType = async () => {
    await deleteRenderConfigMutation({
      variables: { id: config.id },
    })
  }

  const handleDeletePolygonTypeButtonClick = () => {
    if (featureCountData?.renderConfigFeatureCount.featureCount > 0) {
      toast.error('Cannot delete a Polygon with geometries associated')
    } else {
      handleDeletePolygonType()
    }
  }

  const handleSubmit: SubmitHandler<FormFields> = (data) => {
    if (editing) {
      handleUpdatePolygonType(data)
    } else {
      handleCreatePolygonType(data)
    }
    setOpen(false)
  }

  return (
    <>
      <button
        className="p-2 rounded-md text-primary hover:text-text hover:bg-light"
        onClick={() => setOpen(!open)}
      >
        {editing ? (
          'Edit'
        ) : (
          <div className="flex items-center gap-x-2">
            <PlusCircleIcon className="h-5 w-5" />
            <span>Polygon Type</span>
          </div>
        )}
      </button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <Form onSubmit={handleSubmit}>
          <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
            {editing ? 'Edit' : 'Create'} Polygon Type
          </DialogTitle>
          <DialogDescription>
            {editing
              ? 'Update the polygon type details'
              : 'Enter details for the new polygon type'}
          </DialogDescription>
          <DialogBody>
            <div className="flex flex-col gap-4 w-full items-start">
              <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col gap-0 w-full">
                  <Input
                    name="polygonTypeName"
                    label="Name"
                    defaultValue={init.name}
                  />
                </div>
                <div className="flex flex-row sm:flex-col sm:items-start items-center justify-between w-full">
                  <ColorInput
                    name="fillColor"
                    defaultValue={init.fillColor}
                    label="Fill Color"
                    validation={{ required: true }}
                  />
                </div>
                <div className="flex flex-row sm:flex-col sm:items-start items-center justify-between w-full">
                  <ColorInput
                    name="outlineColor"
                    defaultValue={init.outlineColor}
                    label="Outline Color"
                    validation={{ required: true }}
                  />
                </div>
                <div className="flex flex-row sm:flex-col sm:items-start items-center justify-between w-full">
                  <div>
                    <Input
                      name="opacity"
                      label="Opacity"
                      type="number"
                      placeholder="0-1"
                      defaultValue={init.opacity}
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogBody>
          <DialogActions>
            <Button plain onClick={() => setOpen(false)}>
              Cancel
            </Button>
            {editing ? (
              <div className="flex flex-row gap-3">
                <Button
                  color="secondary"
                  type="button"
                  onClick={handleDeletePolygonTypeButtonClick}
                >
                  Delete Type
                </Button>

                <Button color="primary" type="submit">
                  Update Type
                </Button>
              </div>
            ) : (
              <Button color="primary" type="submit">
                Create Type
              </Button>
            )}
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}
