import { Form, Label, SelectField, Submit, TextField } from '@redwoodjs/forms'
import { usStates } from '../statesArray'
import { colorOptions } from '../colorOptions'
import { markerArray } from './markerArray'
import { navigate } from '@redwoodjs/router'

export const OnboardingUserSettings = () => {
  const handleSubmitForm = () => {
    navigate('/onboardingDisclaimer')
  }

  return (
    <div className="w-full flex flex-col items-center justify-center h-[100vh]">
      <div className="rw-form-wrapper w-full lg:w-[30%] border-2 border-gray-300 rounded-lg p-12 bg-slate-50">
        <div className="h-32">
          <img src="/silo-logo.svg" alt="silo logo" className="h-full w-full" />
        </div>
        <Form
          className="flex flex-col gap-8  items-center"
          onSubmit={handleSubmitForm}
        >
          <div className="flex justify-center items-center w-full">
            <Label name="states" className="rw-label mt-0">
              What states will you be working in?
            </Label>
            <SelectField name="states" className="w-24">
              {usStates.map((state) => {
                return <option key={state.name}>{state.name}</option>
              })}
            </SelectField>
          </div>
          <div className="flex-flex-col justify-center items-center w-full">
            <Label name="jobTitle" className="rw-label">
              Specify any types you would like for your geometry.
            </Label>
            <TextField name="jobTitle" className="rw-input" />
          </div>
          <div className="flex flex-col justify-center items-center w-full gap-8">
            <div className="flex flex-col gap-4 w-full items-center">
              <Label name="jobTitle" className="rw-label">
                What colors would you like for your points?
              </Label>
              <div className="flex justify-evenly w-full">
                {colorOptions.map((color) => {
                  return (
                    <div
                      className="flex w-full h-auto justify-center"
                      key={color.hex}
                    >
                      <div
                        className={`w-12 h-12 rounded-full bg-[${color.hex}] cursor-pointer hover:scale-110 transition-transform duration-300`}
                      ></div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="flex flex-col gap-4 w-full items-center">
              <Label name="jobTitle" className="rw-label">
                What markers would you like for your points?
              </Label>
              <div className="flex justify-evenly w-full">
                {markerArray.map((icon) => {
                  return (
                    <div
                      className="flex justify-center w-full h-auto"
                      key={icon.name}
                    >
                      <img
                        alt="map marker option"
                        src={icon.src}
                        className="h-12 cursor-pointer hover:scale-110 transition-transform duration-300"
                      />
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="flex justify-evenly w-full">
              <div className="flex w-full h-auto"></div>
            </div>
          </div>
          <div className="rw-button-group w-full flex justify-center">
            <Submit className="rw-button rw-button-blue w-full p-2">
              Submit
            </Submit>
          </div>
        </Form>
      </div>
    </div>
  )
}
