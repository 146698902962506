import { MapIcon } from '@heroicons/react/24/outline'
import { navigate, routes } from '@redwoodjs/router'
import { useDispatch } from 'react-redux'
import { setFlyoutConfig } from 'src/components/Map/mapSlice'

const SettingsHeader = () => {
  const dispatch = useDispatch()
  const handleCloseSettings = () => {
    dispatch(setFlyoutConfig({ component: '', featureType: null }))
    navigate(routes.map())
  }
  return (
    <header className="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10 w-full">
      <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
        <div className="flex flex-1 items-center gap-x-6">
          <div className="w-[100px]  flex justify-center items-center">
            <img src="/silo-settings-logo.svg" alt="silo logo" />
          </div>
          <button
            className="flex flex-1 justify-end"
            onClick={handleCloseSettings}
          >
            <div className="flex flex-row space-x-1 mr-2 text-sm font-semibold leading-6 text-secondary">
              <MapIcon className="h-6 w-6" />
              <span className="text-secondary">Map</span>
              <span aria-hidden="true">&rarr;</span>
            </div>
          </button>
        </div>
      </div>
    </header>
  )
}

export default SettingsHeader
