type Props = {
  className?: string
}

const Logo = ({ className }: Props) => {
  return (
    <div className={`${className ? className : 'h-full w-full'}`}>
      <img src="/silo-logo.svg" alt="silo logo" />
    </div>
  )
}

export default Logo
