import React, { useState, useRef, useEffect } from 'react'
import { createPopper, Placement } from '@popperjs/core'

interface InfoPopUpProps {
  message: string
  children: React.ReactNode
  position?: Placement
}

const InfoPopUp: React.FC<InfoPopUpProps> = ({
  message,
  children,
  position = 'top',
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const triggerRef = useRef<HTMLSpanElement | null>(null)
  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const popperInstance = useRef<ReturnType<typeof createPopper> | null>(null)

  const showTooltip = () => {
    setIsVisible(true)
  }

  const hideTooltip = () => {
    setIsVisible(false)
  }

  useEffect(() => {
    if (isVisible && triggerRef.current && tooltipRef.current) {
      popperInstance.current = createPopper(
        triggerRef.current,
        tooltipRef.current,
        {
          placement: position,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        }
      )
    }

    // Cleanup the popper instance on unmount or when isVisible changes
    return () => {
      if (popperInstance.current) {
        popperInstance.current.destroy()
        popperInstance.current = null
      }
    }
  }, [isVisible, position])

  return (
    <>
      <span
        ref={triggerRef}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        className="inline-flex"
      >
        {children}
      </span>
      {isVisible && (
        <div
          ref={tooltipRef}
          className="bg-white p-2 border border-gray-300 rounded shadow-lg z-50 text-sm font-normal max-w-xs"
          style={{ position: 'absolute' }}
        >
          {message}
        </div>
      )}
    </>
  )
}

export default InfoPopUp
