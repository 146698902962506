import {
  Description as HeadlessDescription,
  Field as HeadlessField,
  Fieldset as HeadlessFieldset,
  Label as HeadlessLabel,
  Legend as HeadlessLegend,
  type DescriptionProps as HeadlessDescriptionProps,
  type FieldProps as HeadlessFieldProps,
  type FieldsetProps as HeadlessFieldsetProps,
  type LabelProps as HeadlessLabelProps,
  type LegendProps as HeadlessLegendProps,
} from '@headlessui/react'
import clsx from 'clsx'
import type React from 'react'

export function Fieldset({
  className,
  ...props
}: { disabled?: boolean } & HeadlessFieldsetProps) {
  return (
    <HeadlessFieldset
      {...props}
      className={clsx(
        className,
        '[&>*+[data-slot=control]]:mt-6 [&>[data-slot=text]]:mt-1'
      )}
    />
  )
}

export function Legend({ ...props }: HeadlessLegendProps) {
  return (
    <HeadlessLegend
      {...props}
      data-slot="legend"
      className={clsx(
        props.className,
        'lg:text-legend font-semibold text-zinc-950 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-light'
      )}
    />
  )
}

export function FieldGroup({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      {...props}
      data-slot="control"
      className={clsx(className, 'space-y-2')}
    />
  )
}

export function Field({ className, ...props }: HeadlessFieldProps) {
  return (
    <HeadlessField
      className={clsx(
        className,
        '[&>[data-slot=label]+[data-slot=control]]:mt-1',
        '[&>[data-slot=label]+[data-slot=description]]:mt-1',
        '[&>[data-slot=description]+[data-slot=control]]:mt-1',
        '[&>[data-slot=control]+[data-slot=description]]:mt-1',
        '[&>[data-slot=control]+[data-slot=error]]:mt-1',
        '[&>[data-slot=label]]:font-medium'
      )}
      {...props}
    />
  )
}

export function Label({
  className,
  ...props
}: { className?: string } & HeadlessLabelProps) {
  return (
    <HeadlessLabel
      {...props}
      data-slot="label"
      className={clsx(
        className,
        'select-none font-label text-label text-text dark:text-white data-[disabled]:opacity-50 sm:text-label'
      )}
    />
  )
}

interface DescriptionProps extends HeadlessDescriptionProps {
  className?: string
  disabled?: boolean
}

export function Description({
  className,
  disabled,
  ...props
}: DescriptionProps) {
  return (
    <HeadlessDescription
      {...props}
      data-slot="description"
      className={clsx(
        className,
        'text-base/6 text-zinc-500 sm:text-sm/6 dark:text-zinc-400',
        {
          'opacity-50': disabled,
        }
      )}
    />
  )
}

interface ErrorMessageProps extends HeadlessDescriptionProps {
  className?: string
  disabled?: boolean
}

export function ErrorMessage({
  className,
  disabled,
  ...props
}: ErrorMessageProps) {
  return (
    <HeadlessDescription
      {...props}
      data-slot="error"
      className={clsx(
        className,
        'text-base/6 text-red-600 sm:text-sm/6 dark:text-red-500',
        {
          'opacity-50': disabled,
        }
      )}
    />
  )
}
