import { createLogger } from '@redwoodjs/api/logger'
import { redactionsList } from '@redwoodjs/api/logger'

/**
 * Creates a logger with RedwoodLoggerOptions
 *
 * These extend and override default LoggerOptions,
 * can define a destination like a file or other supported pino log transport stream,
 * and sets whether or not to show the logger configuration settings (defaults to false)
 *
 * @param RedwoodLoggerOptions
 *
 * RedwoodLoggerOptions have
 * @param {options} LoggerOptions - defines how to log, such as redaction and format
 * @param {string | DestinationStream} destination - defines where to log, such as a transport stream or file
 * @param {boolean} showConfig - whether to display logger configuration on initialization
 */

const isDevEnvironment = process.env.NODE_ENV === 'development'

/**
 * Creates a logger for prettified error messages in local dev
 * console.error should be unneeded for local dev, logger.error will function the same (but can still be used and will get removed when pushing)
 * console.* calls will still get removed in CI before deploying to production just in case any are left
 * logger.* calls will also be removed in CI before deploying to production via a custom plugin, but are also configured to 'silent' as a failsafe
 */
export const logger = createLogger({
  // options can be adjusted for frontend specific configs
  options: {
    level: isDevEnvironment ? 'trace' : 'silent',
    // add any fields that should be redacted from log after spreading common redactionsList
    redact: [...redactionsList],
    hooks: {},
  },
})
