import { CreateFeatureInput, UpdateFeatureInput } from 'types/graphql'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from 'src/components/catalyst/dialog'
import { Button } from 'src/components/catalyst/button'
import { Text } from 'src/components/catalyst/text'
import { setLayerVisibility } from '../Map/mapSlice'
import { useDispatch } from 'react-redux'

const LayerInactiveWarningDialog = ({
  isOpen,
  setIsOpen,
  featureCandidateData,
  featureTagsData,
  onSave,
  mode,
}) => {
  const dispatch = useDispatch()
  const featureTag =
    featureTagsData &&
    featureCandidateData &&
    featureTagsData.featureTags.find(
      (tag) => tag.id === parseInt(featureCandidateData.tagId)
    )

  const handleActivate = () => {
    dispatch(
      setLayerVisibility({
        layerId: JSON.stringify(featureTag.id),
        desiredState: true,
        section: 'organization',
      })
    )

    onSave(featureCandidateData as CreateFeatureInput | UpdateFeatureInput)

    setIsOpen(null)
  }
  return (
    featureTagsData &&
    featureTag && (
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>{`Warning: ${featureTag.displayName} Layer Inactive`}</DialogTitle>

        <DialogBody>
          <Text className="text-sm">
            {`If you leave the layer inactive, your newly ${
              mode === 'EditFeature' ? 'edited' : 'created'
            } feature will not be visible after creation.`}
          </Text>
        </DialogBody>
        <DialogActions className="flex flex-row justify-between">
          <Button plain onClick={() => setIsOpen(null)}>
            Cancel
          </Button>
          <div className="flex flex-row gap-3">
            <Button
              color="secondary"
              onClick={() => {
                onSave(
                  featureCandidateData as
                    | CreateFeatureInput
                    | UpdateFeatureInput
                )
                setIsOpen(null)
              }}
            >
              Leave Inactive
            </Button>
            <Button color="primary" onClick={handleActivate}>
              Activate
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  )
}

export default LayerInactiveWarningDialog
