import type { Layer } from 'src/components/LayerSelector/LayerTypes'
import type { BoundingBox } from 'src/lib/mapUtils'
import { CountyState } from 'src/components/Map/mapTypes'
import { processCountyState } from 'src/components/Map/stateAndCountyConversion'
import { ApiFeatureType } from 'src/types/features'
import { GEOSERVICE_URI } from 'src/appEnv'
import { logger } from 'src/lib/logger'
import Sentry from 'src/lib/sentry'

enum RequestMethod {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

function objectToQueryString(obj: Record<string, any>): string {
  const params = new URLSearchParams()

  Object.entries(obj).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((item) => params.append(key, item.toString()))
      } else {
        params.append(key, value.toString())
      }
    }
  })

  return params.toString()
}

type fileData = {
  apiFeatureType: ApiFeatureType
  userId: number
  orgId: number
  renderConfigId: number
  type: string
}
export const uploadGeoData = async (file: File, data: fileData) => {
  try {
    const formData = new FormData()
    formData.append('file', file)

    Object.entries(data).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        formData.append(key, value.toString())
      }
    })

    const response = await fetch(`${GEOSERVICE_URI}/upload/features`, {
      method: RequestMethod.POST,
      body: formData,
    })

    if (response.ok) {
      await response.json()
    } else {
      logger.error(response.statusText, 'Failed to upload file')
      throw new Error('Failed to upload file', { cause: response.statusText })
    }
  } catch (error) {
    logger.error(error, 'Error during file upload:')
    Sentry.captureException(error)
    await Sentry.flush(2000)
    throw new Error('Error during file upload', { cause: error })
  }
}

export async function downloadMap(
  url: string,
  fileName: string,
  method = RequestMethod.GET,
  fetchOptions: RequestInit = {}
) {
  try {
    const options: RequestInit = { method, headers: {}, ...fetchOptions }
    const response = await fetch(url, options as RequestInit)

    if (!response.ok) throw new Error(`Failed to download ${fileName}`)
    const blob = await response.blob()
    const downloadUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = downloadUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    a.remove()
  } catch (error) {
    logger.error(`Error downloading ${fileName}:`, error)
    Sentry.captureException(error)
    await Sentry.flush(2000)
    throw error
  }
}
export async function downloadLayer(
  url,
  fileName,
  method = RequestMethod.GET,
  body = null,
  fetchOptions: RequestInit = {}
) {
  try {
    const options: RequestInit = { method, headers: {}, ...fetchOptions }

    if (method === RequestMethod.POST) {
      options.headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(body)
    }

    const response = await fetch(url, options as RequestInit)

    if (!response.ok) throw new Error(`Failed to download ${fileName}`)
    const blob = await response.blob()
    const downloadUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = downloadUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    a.remove()
  } catch (error) {
    logger.error(`Error downloading ${fileName}:`, error)
    Sentry.captureException(error)
    await Sentry.flush(2000)
    throw error
  }
}

export async function downloadPublicLayers(
  aoi: object,
  layerData: Layer[],
  fileType: string
) {
  const payload = {
    ...aoi,
    service_ids: layerData.map((layer) => layer.id),
    file_type: fileType,
  }

  return downloadLayer(
    `${GEOSERVICE_URI}/download/layers/public?${objectToQueryString(payload)}`,
    `public_layers.zip`
  )
}

export async function downloadOrgLayers(
  aoi: object,
  currentUser,
  fileType: string
) {
  const payload = {
    ...aoi,
    organization_id: currentUser.organizationId,
    file_type: fileType,
  }
  return downloadLayer(
    `${GEOSERVICE_URI}/download/layers/org?${objectToQueryString(payload)}`,
    `user_layers.zip`
  )
}

export async function downloadVisibleLayers(
  aoi: object,
  currentUser,
  publicLayerData: Layer[],
  orgLayerData: Layer[],
  fileType: string,
  url_template?: string
) {
  const payload = {
    ...aoi,
    organization_id: currentUser.organizationId,
    file_type: fileType,
    service_ids: publicLayerData.map((layer) => layer.id),
    org_layer_ids: orgLayerData.map((layer) => Number(layer.id)),
    url_template,
  }

  return downloadLayer(
    `${GEOSERVICE_URI}/download/layers/visible?${objectToQueryString(payload)}`,
    `visible_layers.zip`
  )
}

export async function getCounties(boundingBox: BoundingBox) {
  try {
    const params = objectToQueryString({
      xmin: boundingBox.xmin,
      ymin: boundingBox.ymin,
      xmax: boundingBox.xmax,
      ymax: boundingBox.ymax,
    })
    const response = await fetch(`${GEOSERVICE_URI}/counties?${params}`)
    let data: CountyState[] = await response.json()
    data = data.map((item) => processCountyState(item))
    return data
  } catch (error) {
    logger.error(error, `There was an error getting counties`)
    Sentry.captureException(error)
    await Sentry.flush(2000)
  }
}
