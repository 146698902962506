import { getFeatureTypeLabel } from 'src/lib/featureUtils'
import React from 'react'
import { RenderConfig } from 'types/graphql'
import { FeatureType } from 'src/types/features'
import { LineTypeForm } from './lineTypeForm'
import { PointTypeForm } from './pointTypeForm'
import { PolygonTypeForm } from './polygonTypeForm'
import { Heading } from 'src/components/catalyst/heading'

interface FeatureTypeTableProps {
  featureType: FeatureType
  renderConfigs: RenderConfig[]
}

const FeatureForms = {
  [FeatureType.circle]: PointTypeForm,
  [FeatureType.line]: LineTypeForm,
  [FeatureType.fill]: PolygonTypeForm,
}

export const FeatureTypeTable: React.FC<FeatureTypeTableProps> = ({
  featureType,
  renderConfigs,
}) => {
  const FeatureForm = FeatureForms[featureType]

  if (renderConfigs.length === 0) {
    return (
      <>
        <div className="pt-4 pb-6 full-width text-center">
          <Heading>{`You haven't created any ${getFeatureTypeLabel(
            featureType
          )} Types`}</Heading>
          <div className="pt-2">
            <FeatureForm />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="p-0">
      <div className="mt-0 flow-root">
        <div className="content-start -my-2 overflow-x-auto lg:max-h-[40vh] sm:-mx-6 lg:-mx-8">
          <div className="flex flex-col min-w-full py-0 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="sticky">
                <tr>
                  <th
                    scope="col"
                    className="w-14 max-w-14 sm:w-40 sm:max-w-40 overflow-hidden text-ellipsis text-left text-sm font-semibold text-primary"
                  >
                    Name
                  </th>
                  {featureType === FeatureType.circle && (
                    <th
                      scope="col"
                      className="max-w-5 px-3 py-1.5 text-left text-sm font-semibold text-primary"
                    >
                      Color
                    </th>
                  )}
                  {featureType === FeatureType.line && (
                    <>
                      <th
                        scope="col"
                        className="px-3 py-1.5 max-w-20 text-left text-sm font-semibold text-primary"
                      >
                        Color
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-1.5 text-left text-sm font-semibold text-primary"
                      >
                        Width
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-1.5 text-left text-sm font-semibold text-primary"
                      >
                        Cap
                      </th>
                    </>
                  )}
                  {featureType === FeatureType.fill && (
                    <>
                      <th
                        scope="col"
                        className="px-3 py-1.5 text-left text-sm font-semibold text-primary"
                      >
                        Fill
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-1.5 text-left text-sm font-semibold text-primary"
                      >
                        Outline
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-1.5 text-left text-sm font-semibold text-primary"
                      >
                        Opacity
                      </th>
                    </>
                  )}

                  <th scope="col" className="relative py-1.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 overflow-y-auto">
                {renderConfigs.map((config) => (
                  <tr key={config.id}>
                    <td className="whitespace-nowrap w-14 max-w-14 sm:w-40 sm:max-w-40 overflow-hidden text-ellipsis py-4 text-sm font-medium text-primary sm:pl-0">
                      {config.name}
                    </td>
                    {featureType === FeatureType.circle && (
                      <>
                        <td className="whitespace-nowrap max-w-5 px-3 py-4 text-sm text-gray-500">
                          <div
                            style={{
                              backgroundColor: config.paint['circle-color'],
                            }}
                            className="full-width h-6 rounded-md"
                          ></div>
                        </td>
                      </>
                    )}
                    {featureType === FeatureType.line && (
                      <>
                        <td className="whitespace-nowrap max-w-20 px-3 py-4 text-sm text-gray-500">
                          <div
                            style={{
                              backgroundColor: config.paint['line-color'],
                            }}
                            className="full-width h-6 rounded-md"
                          ></div>
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 pl-8 text-sm font-medium text-primary">
                          {config.paint['line-width']}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 pl-4 text-sm font-medium text-primary">
                          {config.layout['line-cap']}
                        </td>
                      </>
                    )}
                    {featureType === FeatureType.fill && (
                      <>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div
                            style={{
                              backgroundColor: config.paint['fill-color'],
                            }}
                            className="full-width h-6 rounded-md"
                          ></div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div
                            style={{
                              backgroundColor:
                                config.paint['fill-outline-color'],
                            }}
                            className="full-width h-6 rounded-md"
                          ></div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {config.paint['fill-opacity']}
                        </td>
                      </>
                    )}

                    <td className="relative whitespace-nowrap py-4 mr- pr-4 text-right text-sm font-medium sm:pr-0">
                      <FeatureForm config={config} mode="edit" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pt-1 pb-2 self-center">
              <FeatureForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
