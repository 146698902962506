import { Field, Label } from 'src/components/catalyst/fieldset'
import { Textarea } from 'src/components/catalyst/textarea'

const TextAreaComponent = () => {
  return (
    <Field>
      <Label>Text Area</Label>
      <Textarea name="description" className="w-form-control" />
    </Field>
  )
}

export default TextAreaComponent
