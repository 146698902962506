import { useAuth } from 'src/auth'

const Flyout = ({ children }) => {
  const { isAuthenticated } = useAuth()
  return (
    <div
      className={`absolute left-[50px] s sm:left-14 rounded-md bg-white ${
        isAuthenticated
          ? 'top-[72px] sm:top-[56px] flyout-height-mobile sm:flyout-height'
          : 'flyout-top-mobile sm:flyout-top flyout-height-mobile-public sm:flyout-height-public'
      } left-0 m-3.5 flyout-width-mobile sm:flyout-width overflow-y-auto`}
    >
      {children}
    </div>
  )
}

export default Flyout
