import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Button } from 'src/components/catalyst/button'
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from 'src/components/catalyst/dropdown'
import {
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from 'src/components/catalyst/fieldset'
import { Input } from 'src/components/catalyst/input'
import { Text } from 'src/components/catalyst/text'
import { states } from './states'

const OnboardingForm = () => {
  return (
    <div className="w-lg-form-width flex font-roboto flex-col border-2 px-16 pt-4 pb-12 items-center border-border rounded-xl">
      <span className=" text-black dark:text-white">
        <img
          src="/silo-logo.svg"
          alt="silo logo"
          className="w-md-image text-black dark:text-white"
        />
      </span>

      <form action="/orders" method="POST" className=" flex-col gap-4 w-full">
        <Fieldset className="flex flex-col ">
          <Legend className=" text-xl text-center">Create Organization</Legend>

          <FieldGroup className="flex flex-col gap-2">
            <Field>
              <Label className="text-lg font-bold">Name</Label>
              <Input name="street_address" className="border-1 border-border" />
            </Field>
            <Field>
              <Label className="text-lg font-bold">Address</Label>
              <Input name="street_address" className="border-1 border-border" />
            </Field>
            <Field>
              <Label className="text-lg font-bold">City</Label>
              <Input name="street_address" className="border-1 border-border" />
            </Field>
          </FieldGroup>
          <FieldGroup className="flex items-center w-full justify-center gap-4 ">
            <Field className="w-[50%] mt-2">
              <Dropdown>
                <DropdownButton
                  color="white"
                  className="w-full h-[40px] dark:bg-white/5  "
                >
                  <Text className="w-full flex justify-start">State</Text>
                  <ChevronDownIcon
                    className="max-h-[15px] dark:text-white"
                    color="black"
                  />
                </DropdownButton>
                <DropdownMenu>
                  {states.map((state) => {
                    return (
                      <DropdownItem key={state.abbreviation}>
                        {state.abbreviation}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            </Field>
            <Field className="w-full !space-y-0">
              <Input
                name="zipcode"
                className="border-1 border-border !space-y-0"
                placeholder="Zipcode"
              />
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field>
              <Label>Industry</Label>
              <Input name="industry" className="border-1 border-border" />
            </Field>
          </FieldGroup>
        </Fieldset>
        <Button color="primary" className="w-full mt-8 ">
          Save
        </Button>
      </form>
    </div>
  )
}

export default OnboardingForm
