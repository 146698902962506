import { Button } from 'src/components/catalyst/button'
import { Text } from 'src/components/catalyst/text'

const Buttons = () => {
  return (
    <div className="flex flex-col gap-4 items-center">
      <Text className="!text-2xl  font-roboto">Buttons</Text>

      <div className="flex justify-center items-center gap-12">
        <Button color="primary" className=" w-button">
          Primary
        </Button>
        <Button color="secondary" type="button" className=" w-button">
          Secondary
        </Button>
        <Button color="muted" className=" w-button" type="button">
          Muted
        </Button>
        <Button color="accent" className=" w-button" type="button">
          Accent
        </Button>
        <Button color="light" className=" w-button" type="button">
          Light
        </Button>
      </div>
    </div>
  )
}

export default Buttons
