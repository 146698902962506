import { CountyState } from './mapTypes'

export const processCountyState = ({ county, state }: CountyState) => {
  return {
    county: county.toLowerCase(),
    state: getStateAbbreviation(state).toLowerCase(),
  }
}

export const getStateAbbreviation = (state: string): string => {
  // You can extend this function to include more state abbreviations
  const stateAbbreviations = {
    Washington: 'wa',
    Oregon: 'or',
    'New Mexico': 'nm',
    Nevada: 'nv',
    California: 'ca',
    Arizona: 'az',
  }

  // Default to the original state name if no abbreviation is found
  return stateAbbreviations[state] || state
}
