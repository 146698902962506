import { Layer, LayerType, LayerScope, ServiceType } from './LayerTypes'

export const baseLayers: Layer[] = [
  {
    type: LayerType.layer,
    id: 'satellite',
    displayName: 'Satellite',
    scope: LayerScope.BASE,
    source: {
      service: ServiceType.MAPBOX,
      displayName: 'Mapbox',
      url: `mapbox://styles/mapbox/satellite-v9`,
    },
    active: true,
  },
  {
    type: LayerType.layer,
    id: 'satellite_street',
    displayName: 'Satellite Street',
    scope: LayerScope.BASE,
    source: {
      service: ServiceType.MAPBOX,
      displayName: 'Mapbox',
      url: `mapbox://styles/mapbox/satellite-streets-v12`,
    },
    active: false,
  },
  {
    type: LayerType.layer,
    id: 'street',
    displayName: 'Street',
    scope: LayerScope.BASE,
    source: {
      service: ServiceType.MAPBOX,
      displayName: 'Mapbox',
      url: `mapbox://styles/mapbox/streets-v12`,
    },
    active: false,
  },
  // stock mapbox base layers can be found here: https://docs.mapbox.com/api/maps/styles/
]

export const userLayers = [
  {
    id: 1,
    name: 'Survey',
    color: '#FFA500',
    isActive: false,
    isRenderedInMap: false,
    userId: null,
    organizationId: null,
  },
  {
    id: 2,
    name: 'Civil',
    color: '#FF0000',
    isActive: false,
    isRenderedInMap: false,
    userId: null,
    organizationId: null,
  },
  {
    id: 3,
    name: 'Environmental',
    color: '#FFFFFF',
    isActive: false,
    isRenderedInMap: false,
    userId: null,
    organizationId: null,
  },
  {
    id: 4,
    name: 'Geotech',
    color: '#FFC0CB',
    isActive: false,
    isRenderedInMap: false,
    userId: null,
    organizationId: null,
  },
  {
    id: 5,
    name: 'Planning',
    color: '#FF0000',
    isActive: false,
    isRenderedInMap: false,
    userId: null,
    organizationId: null,
  },
]
