import { useState } from 'react'
import { Button } from 'src/components/catalyst/button'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from 'src/components/catalyst/dialog'
import { Field, Label } from 'src/components/catalyst/fieldset'
import { Input } from 'src/components/catalyst/input'

const DialogComponent = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="font-roboto">
      <Button
        color="primary"
        className=" w-button"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        Dialog
      </Button>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Update User Info</DialogTitle>
        <DialogDescription>
          Your name will be updated once you have submitted this form.
        </DialogDescription>
        <DialogBody>
          <Field>
            <Label>Full Name</Label>
            <Input name="Full Name" placeholder="John Smith" />
          </Field>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => setIsOpen(false)} color="accent">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogComponent
