import {
  Checkbox,
  CheckboxField,
  CheckboxGroup,
} from 'src/components/catalyst/checkbox'
import {
  Description,
  Fieldset,
  Label,
  Legend,
} from 'src/components/catalyst/fieldset'
import { Text } from 'src/components/catalyst/text'

const CheckboxComponent = () => {
  return (
    <Fieldset className="font-monsterrat">
      <Legend>Checkbox</Legend>
      <Text>Are you an owner or employee?</Text>
      <CheckboxGroup>
        <CheckboxField>
          <Checkbox
            name="owner"
            value="isOwner"
            color="accent"
            defaultChecked
          />
          <Label>Owner</Label>
          <Description>I am the owner of an organization.</Description>
        </CheckboxField>
        <CheckboxField>
          <Checkbox name="employee" value="isEmployee" color="accent" />
          <Label>Employee</Label>
          <Description>I&apos;m an employee of an organization.</Description>
        </CheckboxField>
      </CheckboxGroup>
    </Fieldset>
  )
}

export default CheckboxComponent
