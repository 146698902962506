import { FeatureType, ApiFeatureType } from 'src/types/features'
import { GeoJsonTypes, FeatureCollection } from 'geojson'
import { getFeatureFromCollection } from './geoJsonUtils'

export const getFeatureTypeLabel = (type: FeatureType): string => {
  switch (type) {
    case FeatureType.circle:
      return 'Point'
    case FeatureType.line:
      return 'Line'
    case FeatureType.fill:
      return 'Polygon'
    default:
      return 'Unsupported Type'
  }
}

const fromApiTypeMap: { [key in ApiFeatureType]: FeatureType } = {
  point_geom: FeatureType.circle,
  line_geom: FeatureType.line,
  polygon_geom: FeatureType.fill,
  FillExtrusion: FeatureType['fill-extrusion'],
  Heatmap: FeatureType.heatmap,
  Hillshade: FeatureType.hillshade,
  Raster: FeatureType.raster,
  Symbol: FeatureType.symbol,
  Background: FeatureType.background,
  Unsupported: FeatureType.unsupported,
}

const toApiTypeMap: { [key in FeatureType]: ApiFeatureType } = {
  [FeatureType.circle]: 'point_geom',
  [FeatureType.line]: 'line_geom',
  [FeatureType.fill]: 'polygon_geom',
  [FeatureType['fill-extrusion']]: 'FillExtrusion',
  [FeatureType.heatmap]: 'Heatmap',
  [FeatureType.hillshade]: 'Hillshade',
  [FeatureType.raster]: 'Raster',
  [FeatureType.symbol]: 'Symbol',
  [FeatureType.background]: 'Background',
  [FeatureType.unsupported]: 'Unsupported',
}

export const fromApiFeatureType = (type: string): FeatureType =>
  fromApiTypeMap[type]

export const toApiFeatureType = (type: FeatureType): ApiFeatureType =>
  toApiTypeMap[type]

const fromGeoJsonTypeMap: { [key in GeoJsonTypes]: FeatureType } = {
  Point: FeatureType.circle,
  LineString: FeatureType.line,
  Polygon: FeatureType.fill,
  MultiLineString: FeatureType.unsupported,
  MultiPolygon: FeatureType.unsupported,
  MultiPoint: FeatureType.unsupported,
  GeometryCollection: FeatureType.unsupported,
  Feature: FeatureType.unsupported,
  FeatureCollection: FeatureType.unsupported,
}

export const fromGeoJsonTypes = (type: GeoJsonTypes): FeatureType => {
  return fromGeoJsonTypeMap[type]
}

/**
 * Retrieves the Feature Type of a FeatureCollection.
 *
 * @param drawingData The FeatureCollection to get the feature type from
 * @returns The Feature Type or null if not found
 */
export function getFeatureType(
  drawingData: FeatureCollection | null | undefined
): FeatureType | null {
  const feature = getFeatureFromCollection(drawingData)
  if (feature && feature.geometry) {
    return fromGeoJsonTypes(feature.geometry.type)
  }
  return null
}
