import { Auth0Client, type Auth0ClientOptions } from '@auth0/auth0-spa-js'
import { createAuth } from '@redwoodjs/auth-auth0-web'
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_REDIRECT_URI,
} from 'src/appEnv'

export const defaultAuth0Params: Auth0ClientOptions = {
  domain: AUTH0_DOMAIN || '',
  clientId: AUTH0_CLIENT_ID || '',
  authorizationParams: {
    redirect_uri: AUTH0_REDIRECT_URI,
    audience: AUTH0_AUDIENCE,
  },

  // Storing tokens in the browser's local storage provides persistence across page refreshes and browser tabs.
  // But if an attacker can run JavaScript in your SPA using a cross-site scripting (XSS) attack,
  // they can retrieve the tokens stored in local storage.
  // See https://auth0.com/docs/libraries/auth0-spa-js#change-storage-options.
  cacheLocation: 'localstorage',

  // `useRefreshTokens` is required for automatically extending sessions beyond what's set in the initial JWT expiration.
  // See https://auth0.com/docs/tokens/refresh-tokens.
  useRefreshTokens: true,
}

export const auth0 = new Auth0Client(defaultAuth0Params)
export const { AuthProvider, useAuth } = createAuth(auth0)
