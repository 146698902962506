import { TeamMemberCard } from './TeamMemberCard'

import { Subheading } from 'src/components/catalyst/heading'
import { UserRoles } from 'src/roles'
import { Invitation, User } from 'types/graphql'

interface TeamMembersListProps {
  data: User[] | Invitation[]
  allowedRoles: UserRoles[]
  handleDelete: (id: number, userType: string) => Promise<void>
}

function isUserArray(data: User[] | Invitation[]): data is User[] {
  return (data as User[])[0]?.email !== undefined
}

export const TeamMembersList: React.FC<TeamMembersListProps> = ({
  data,
  allowedRoles,
  handleDelete,
}) => {
  const dataType = isUserArray(data) ? 'User' : 'Invitation'

  return (
    <>
      {dataType === 'User' ? (
        <Subheading className="text-lg font-semibold mb-2 mt-4">
          Active Team Members
        </Subheading>
      ) : (
        <Subheading className="text-lg font-semibold mb-2 mt-4">
          Invited Members
        </Subheading>
      )}
      <ul className="divide-y divide-gray-200 w-full">
        {data.map((item: User | Invitation) => (
          <TeamMemberCard
            key={item.id}
            item={item}
            allowedRoles={allowedRoles}
            handleDelete={handleDelete}
          />
        ))}
      </ul>
    </>
  )
}
