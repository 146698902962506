import RedMarker from './redMarker.png'
import TackMarker from './tack.png'
import TransparentMarker from './transparentMarker.png'
import BlackPin from './blackPin.png'
import BlueMarker from './blueMarker.png'
import RedPin from './redPin.png'

export const markerArray = [
  {
    name: 'blueMarker',
    src: BlueMarker,
  },
  {
    name: 'blackPin',
    src: BlackPin,
  },
  {
    name: 'redPin',
    src: RedPin,
  },
  {
    name: 'redMarker',
    src: RedMarker,
  },
  {
    name: 'tack',
    src: TackMarker,
  },
  {
    name: 'transparentMarker',
    src: TransparentMarker,
  },
]
