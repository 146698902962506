import { Fragment, useState } from 'react'
import { Disclosure, Popover, Transition } from '@headlessui/react'
import { useLazyQuery } from '@apollo/client'
import { Form, FormError } from '@redwoodjs/forms'
import { Button } from 'src/components/catalyst/button'
import FullscreenOverlay from 'src/components/FullscreenOverlay/FullscreenOverlay'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { defaultAuth0Params, auth0 } from 'src/auth'
import Scope from 'src/components/Resources/Scope'

import Input from '../catalyst/rw/Input'
import { navigate, routes } from '@redwoodjs/router'
import LogoButton from '../LogoButton/LogoButton'

const QUERY = gql`
  query verifyAuthCode($authCode: String!, $email: String!) {
    verifyAuthCode(authCode: $authCode, email: $email) {
      success
      message
    }
  }
`

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  const [gateModalOpen, setGateModalOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const [verifyAuthCode, { error, loading }] = useLazyQuery(QUERY)
  const handleCheckAuthCode = async (input) => {
    const response = await verifyAuthCode({ variables: input })
    const { data, error } = response
    const { message, success } = !error && data.verifyAuthCode

    if (success) {
      auth0.loginWithRedirect({
        ...defaultAuth0Params,
        authorizationParams: {
          ...defaultAuth0Params.authorizationParams,
          allow_signup: true,
          allow_login: false,
          initial_screen: 'signUp',
        },
      })
    } else {
      setValidationMessage(message)
    }
  }
  const handleLogin = () => {
    auth0.loginWithRedirect({
      ...defaultAuth0Params,
      authorizationParams: {
        ...defaultAuth0Params.authorizationParams,
        allow_signup: false,
        allow_login: true,
        initial_screen: 'login',
      },
    })
  }

  return (
    <header className="fixed top-0 left-0 w-full z-50 bg-light h-[8vh] shadow-">
      <nav
        className="mx-auto flex max-w-full items-center justify-between p-6 lg:px-8 h-full"
        aria-label="Global"
      >
        <div className="flex flex-row justify-between content-center lg:justify-start w-full">
          <LogoButton />

          <button
            type="button"
            className="-m-2.5 -mr-3 lg:hidden inline-flex items-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" id="hamburger" />
          </button>

          <Popover.Group className="hidden pl-16 lg:flex lg:gap-x-12 items-center ">
            <Popover className="relative ">
              <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                Scope
                <ChevronDownIcon
                  className="h-5 w-5 flex-none text-gray-400"
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute -left-8 top-full w-[500px] z-10 mt-3 flex rounded-xl bg-white p-2 shadow-lg overflow-y-auto ">
                  <Scope />
                </Popover.Panel>
              </Transition>
            </Popover>
          </Popover.Group>
        </div>
        <div className="hidden items-center lg:w-full lg:flex lg:flex-row lg:justify-end">
          <Button
            plain={true}
            className="mt-0"
            type="button"
            onClick={handleLogin}
          >
            Sign In
          </Button>
          <span>/</span>
          <Button
            id="signup-button"
            outline
            onClick={() => setGateModalOpen(true)}
            className="ml-2"
          >
            Sign Up
          </Button>
        </div>
      </nav>
      <FullscreenOverlay
        isOpen={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      >
        <div className="flex flex-col">
          <Button
            plain
            type="button"
            onClick={() => {
              setMobileMenuOpen(false)
              setGateModalOpen(true)
            }}
            className="w-full flex flex-row justify-between items-center"
          >
            <span>Sign Up</span>
            <ChevronRightIcon
              className="h-5 w-5 flex-none"
              aria-hidden="true"
            />
          </Button>
          <Button
            plain
            type="button"
            onClick={handleLogin}
            className="w-full flex flex-row justify-between items-center"
          >
            <span>Log In</span>
            <ChevronRightIcon
              className="h-5 w-5 flex-none"
              aria-hidden="true"
            />
          </Button>
          <Disclosure as="div" className="overflow-auto">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full p-[.85rem] items-center justify-between rounded-lg text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  <span>Scope</span>
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'rotate-180' : '',
                      'h-5 w-5 flex-none'
                    )}
                    aria-hidden="true"
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="mt-2 space-y-2">
                  <Scope />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </FullscreenOverlay>
      <FullscreenOverlay
        isOpen={gateModalOpen}
        onClose={() => setGateModalOpen(false)}
        className="z-[40]"
      >
        <section className="p-8 bg-white flex flex-col gap-4 justify-center w-full h-full">
          <div className="flex flex-col space-y-5 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 self-center">
            <h2 className="font-semibold text-lg">
              Please enter the code you received from a Silo team member.
            </h2>
            <Form
              onSubmit={handleCheckAuthCode}
              className="flex flex-col space-y-4"
            >
              <Input
                label="Auth Code"
                name="authCode"
                validation={{ required: 'Auth Code required' }}
                inputProps={{ disabled: loading }}
              />
              <Input
                label="Registered Email"
                name="email"
                validation={{ required: 'Email required' }}
                inputProps={{ disabled: loading }}
              />
              <Button
                color="primary"
                className="mt-4"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Validating...' : 'Submit'}
              </Button>
              <FormError error={error} />
              {validationMessage && (
                <p className="text-sm text-red-500">{validationMessage}</p>
              )}
            </Form>
            <div className="pt-4 flex flex-row gap-4 w-full items-center justify-center">
              <h4>{"Don't have a code?"}</h4>
              <Button
                outline
                type="button"
                onClick={() => navigate(routes.lead())}
              >
                Request Access
              </Button>
            </div>
          </div>
        </section>
      </FullscreenOverlay>
    </header>
  )
}
