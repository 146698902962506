import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { useDispatch } from 'react-redux'
import { setLayerVisibility } from '../Map/mapSlice'
import { LayerDataSection } from '../LayerSelector/LayerTypes'
import LayerLeafNode from '../LayerLeafNode/LayerLeafNode'
import type { Layer, LayerGroup } from '../LayerSelector/LayerTypes'
import { LayerType } from '../LayerSelector/LayerTypes'

type LayerGroupProps = {
  layerGroup: LayerGroup
  showGroupName?: boolean
  section: LayerDataSection
}

const LayerGroup = ({
  layerGroup,
  showGroupName = true,
  section,
}: LayerGroupProps) => {
  const dispatch = useDispatch()
  return (
    <Disclosure defaultOpen={layerGroup.active}>
      {({ open }) => (
        <>
          {showGroupName && (
            <DisclosureButton
              className="flex w-full py-1 px-2 my-1 -ml-3 text-left text-sm font-medium text-gray-800 hover:bg-gray-200 hover:rounded-lg"
              onClick={() => {
                dispatch(
                  setLayerVisibility({
                    layerId: layerGroup.id,
                    desiredState: !layerGroup.active,
                    section,
                  })
                )
              }}
            >
              <ChevronUpIcon
                className={`${
                  open ? 'rotate-180 transform' : 'rotate-90 transform'
                } mr-1 h-5 w-5 text-gray-500`}
              />
              <h2 className={`select-none ${open ? 'font-semibold' : ''}`}>
                {layerGroup.displayName}
              </h2>
            </DisclosureButton>
          )}
          <DisclosurePanel className="pr-0 pb-0 pt-0 text-sm text-gray-500">
            <ul className="pl-4">
              {layerGroup.children.map((child) => {
                switch (child.type) {
                  case LayerType.layer:
                    return (
                      <LayerLeafNode
                        key={child.id}
                        leafNode={child as Layer}
                        section={section}
                      />
                    )
                  case LayerType.layerGroup:
                    return (
                      <LayerGroup
                        key={child.id}
                        layerGroup={child as LayerGroup}
                        section={section}
                      />
                    )
                }
              })}
            </ul>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  )
}

export default LayerGroup
