import { FeatureCollection, Feature, Geometry } from 'geojson'

/**
 * Safely retrieves a feature from a FeatureCollection.
 *
 * @param featureCollection The FeatureCollection to get the feature from
 * @param index The index of the feature to retrieve (default: 0)
 * @returns The requested Feature or null if not found
 * @throws Error if the featureCollection is null or undefined
 */
export function getFeatureFromCollection(
  featureCollection: FeatureCollection | null | undefined,
  index = 0
): Feature<Geometry> | null {
  if (!featureCollection) {
    throw new Error('FeatureCollection is null or undefined')
  }

  if (featureCollection.features.length === 0) {
    console.warn('FeatureCollection is empty')
    return null
  }

  if (index < 0 || index >= featureCollection.features.length) {
    console.warn(`Invalid index ${index}. Using index 0 instead.`)
    index = 0
  }

  return featureCollection.features[index] || null
}

// Retrieves the Geometry Type of a GeoJSON Geometry object.
type GeometryType =
  | 'Point'
  | 'LineString'
  | 'Polygon'
  | 'MultiPoint'
  | 'MultiLineString'
  | 'MultiPolygon'
  | 'GeometryCollection'

export function isGeometryType<T extends Geometry>(
  geometry: Geometry,
  type: GeometryType
): geometry is T {
  return geometry.type === type
}
