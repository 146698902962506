export const RENDER_CONFIGS_BY_ORG_QUERY = gql`
  query getRenderConfigs($input: RenderConfigsByOrganizationInput!) {
    renderConfigs: renderConfigsByOrganization(input: $input) {
      id
      createdById
      organizationId
      name
      featureType
      layout
      paint
    }
  }
`

export const RENDER_CONFIG_FEATURE_COUNT = gql`
  query getRenderConfigFeatureCount($id: Int!) {
    renderConfigFeatureCount: renderConfigFeatureCount(id: $id) {
      featureCount
    }
  }
`

export const CREATE_RENDER_CONFIG_MUTATION = gql`
  mutation CreateRenderConfig($input: CreateRenderConfigInput!) {
    createRenderConfig(input: $input) {
      createdById
      featureType
      layout
      name
      organizationId
      paint
    }
  }
`

export const UPDATE_RENDER_CONFIG_MUTATION = gql`
  mutation UpdateRenderConfig($id: Int!, $input: UpdateRenderConfigInput!) {
    updateRenderConfig(id: $id, input: $input) {
      id
      createdById
      organizationId
      name
      featureType
      layout
      paint
    }
  }
`

export const DELETE_RENDER_CONFIG_MUTATION = gql`
  mutation deleteRenderConfig($id: Int!) {
    deleteRenderConfig(id: $id) {
      id
    }
  }
`

export const GET_RENDER_CONFIG_BY_ID_QUERY = gql`
  query getRenderConfig($id: Int!) {
    renderConfig(id: $id) {
      id
      createdById
      organizationId
      name
      featureType
      layout
      paint
    }
  }
`
