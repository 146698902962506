import React, { useState } from 'react'
import { useAuth } from 'src/auth'
import { toast } from '@redwoodjs/web/dist/toast'
import FlyoutAction from '../FlyoutAction/FlyoutAction'
import { SubmitHandler } from '@redwoodjs/forms'
import { uploadGeoData } from 'src/services/geoservice'
import { useQuery } from '@apollo/client'
import FileUploadForm from '../FileUploadForm/FileUploadForm'
import { setFlyoutConfig } from '../Map/mapSlice'
import { useDispatch } from 'react-redux'
import { ApiFeatureType, ApiUploadType } from 'src/types/features'

const QUERY = gql`
  query getUploadPrerequisites(
    $organizationId: Int!
    $renderConfigsInput: RenderConfigsByOrganizationInput!
  ) {
    projects: projectsByOrganization(organizationId: $organizationId) {
      name
      id
    }
    renderConfigs: renderConfigsByOrganization(input: $renderConfigsInput) {
      id
      createdById
      organizationId
      name
      featureType
      layout
      paint
    }
    featureTags {
      id
      type
      displayName
    }
  }
`

const FileUploadComponent = ({ onClose }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const { currentUser } = useAuth()
  const dispatch = useDispatch()

  const { data, loading } = useQuery(QUERY, {
    variables: {
      organizationId: currentUser?.organizationId,
      renderConfigsInput: {
        organizationId: currentUser?.organizationId,
      },
    },
  })

  const { featureTags, projects, renderConfigs } = data || {}

  type FormFields = {
    apiFeatureType: ApiFeatureType
    type?: ApiUploadType
    renderConfigId: number
    projectId: number
    tagId: number
  }

  const handleSubmit: SubmitHandler<FormFields> = async ({
    apiFeatureType,
    renderConfigId,
    type,
    projectId,
    tagId,
  }) => {
    const inputData = {
      apiFeatureType,
      userId: currentUser.id as number,
      orgId: currentUser.organizationId as number,
      renderConfigId,
      type,
      projectId,
      tagId,
    }

    try {
      await uploadGeoData(selectedFile, inputData)
      toast.success('File uploaded successfully')
      dispatch(setFlyoutConfig({ component: '' }))
    } catch (error) {
      toast.error('File upload failed')
    }
  }

  return (
    <FlyoutAction
      onClose={onClose}
      headerText="Upload Geometry Data"
      buttonText="Save"
      handleSubmit={handleSubmit}
    >
      <FileUploadForm
        setSelectedFile={setSelectedFile}
        renderConfigs={renderConfigs}
        renderConfigsLoading={loading}
        projects={projects}
        loadingProjects={loading}
        featureTags={featureTags}
        loadingFeatureTags={loading}
      />
    </FlyoutAction>
  )
}

export default FileUploadComponent
