import { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import BaseLayerItem from '../BaseLayerItem/BaseLayerItem'
import SidebarSectionDisclosure from '../SidebarSectionDisclosure/SidebarSectionDisclosure'

const BaseLayerSelector: FC = () => {
  const baseLayers = useSelector((state: RootState) => state.map.baseLayers)
  const selected = baseLayers.find((layer) => layer.active)
  return (
    <SidebarSectionDisclosure
      label="Base Map"
      first={true}
      secondaryLabel={selected?.displayName}
      content={
        <ul className="flex flex-wrap space-between p-4">
          {baseLayers.map((layer) => (
            <BaseLayerItem key={layer.id} layer={layer} />
          ))}
        </ul>
      }
    />
  )
}

export default BaseLayerSelector
