import { PlusIcon } from '@heroicons/react/24/outline'
import { Button } from 'src/components/catalyst/button'

import {
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from 'src/components/catalyst/fieldset'
import { Input } from 'src/components/catalyst/input'
import DropdownComponent from './DropdownComponent'

const GeoForm = () => {
  return (
    <form
      action="/orders"
      method="POST"
      className="border-2 border-border px-form-padding-x py-form-padding-y flex font-roboto flex-col items-center gap-4 rounded-xl w-md-form-width"
    >
      <Fieldset className="w-full">
        <Legend className=" text-legend font-roboto">
          Create Geometry Form
        </Legend>
        <FieldGroup className="flex flex-col gap-4 items-center justify-center space-y-0">
          <DropdownComponent />
          <DropdownComponent />
          <DropdownComponent>
            <PlusIcon className="h-icon text-dark dark:text-primary" />
          </DropdownComponent>
        </FieldGroup>
        <FieldGroup>
          <Field>
            <Label className="text-lg font-semibold">Description</Label>
            <Input name="street_address" />
          </Field>
        </FieldGroup>
      </Fieldset>
      <Button color="primary" className="mx-4 w-full  mt-2">
        Save
      </Button>
    </form>
  )
}

export default GeoForm
