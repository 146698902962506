import MapLayout from './MapLayout'

type MapLayoutProps = {
  children?: React.ReactNode
}

const MapLayoutProvider = ({ children }: MapLayoutProps) => {
  return <MapLayout>{children}</MapLayout>
}

export default MapLayoutProvider
