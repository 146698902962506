import { XMarkIcon } from '@heroicons/react/24/outline'
import { Form } from '@redwoodjs/forms'
import { Button } from '../catalyst/button'
import { useAuth } from 'src/auth'

const FlyoutAction = ({
  onClose,
  handleSubmit,
  headerText,
  buttonText,
  children,
}) => {
  const { isAuthenticated } = useAuth()
  return (
    <div id="flyout-action" className="rounded-md  overflow-hidden w-full">
      <header className="flex px-4 py-[.5rem] border-b border-gray-300 bg-gray-100 justify-between items-center">
        <h2 className="py-[.25rem] text-md font-semibold text-primary">
          {headerText}
        </h2>
        <button onClick={onClose}>
          <XMarkIcon className="h-5 w-5 hover:text-primary" />
        </button>
      </header>
      <Form onSubmit={handleSubmit} className="bg-white">
        <div className="px-4 pt-4  flex flex-col items-start gap-2">
          {children}
        </div>
        <div className="w-full flex justify-center pb-4 pt-2 px-4">
          <Button
            color="primary"
            disabled={!isAuthenticated}
            type="submit"
            className="w-full"
          >
            {isAuthenticated ? buttonText : `Sign up to ${buttonText}!`}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default FlyoutAction
