import { useLocation } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'
import { defaultAuth0Params, auth0 } from 'src/auth'
import Header from 'src/components/TailwindUI/header'
import { Button } from 'src/components/catalyst/button'
import { InvitationStatus } from 'src/types/enums'

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

const toLowerCase = (string) => {
  return string.toLowerCase()
}

const InvitationPage = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const adminEmail = queryParams.get('adminEmail')
  const invitationStatus = queryParams.get('invitation')

  const handleLogin = () => {
    auth0.loginWithRedirect({
      ...defaultAuth0Params,
      authorizationParams: {
        ...defaultAuth0Params.authorizationParams,
        allow_signup: false,
        allow_login: true,
        initial_screen: 'login',
      },
    })
  }

  // only allow access to this page with an invitation
  if (
    invitationStatus !== InvitationStatus.ACCEPTED &&
    invitationStatus !== InvitationStatus.DECLINED
  ) {
    return <h1>Invalid Invitation</h1>
  }

  return (
    <>
      <Metadata
        title="Accept or Decline Invitation"
        description="Invitation page"
      />
      <Header />
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <h1 className="text-4xl font-bold">
            Invitation {capitalizeFirstLetter(invitationStatus)}
          </h1>
          <p className="mt-4 text-lg">
            You have {toLowerCase(invitationStatus)} {adminEmail}
            &apos;s invitation to join their organization.
          </p>
          <Button
            onClick={handleLogin}
            className="mt-6 text-white px-6 py-3 rounded-md"
            color="primary"
          >
            Sign In
          </Button>
        </div>
      </div>
    </>
  )
}

export default InvitationPage
