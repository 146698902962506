import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  CreateFeatureInput,
  FeatureTag,
  UpdateFeatureInput,
  Project,
} from 'types/graphql'
import { Form, FormError, RWGqlError, SubmitHandler } from '@redwoodjs/forms'
import { useAuth } from 'src/auth'
import { useQuery } from '@apollo/client'
import { toast } from '@redwoodjs/web/dist/toast'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { PlusIcon } from '@heroicons/react/20/solid'
import { FeatureType } from 'src/types/features'
import { RENDER_CONFIGS_BY_ORG_QUERY } from 'src/queries/renderConfigs'
import { PROJECT_BY_ORGANIZATION_QUERY } from 'src/queries/projects'
import { FEATURE_TAGS_QUERY } from 'src/queries/featureTags'
import Select from 'src/components/catalyst/rw/Select'
import Input from 'src/components/catalyst/rw/Input'
import { Button } from 'src/components/catalyst/button'
import { accumulateActiveLayers } from 'src/lib/layerUtils'
import LayerInactiveWarningDialog from 'src/components/LayerInactiveWarningDialog/LayerInactiveWarningDialog'
import CreateProjectDialog from 'src/components/Feature/CreateProjectDialog'
import { getFeatureTypeLabel } from 'src/lib/featureUtils'
import { toInteger } from 'lodash'

interface FeatureFormProps {
  featureType: FeatureType
  onSave?: (input: CreateFeatureInput) => void
  onUpdate?: (input: UpdateFeatureInput) => void
  creationError: RWGqlError
  loading: boolean
}

const FeatureForm = ({
  featureType,
  onSave,
  onUpdate,
  creationError,
  loading,
}: FeatureFormProps) => {
  const { currentUser, isAuthenticated } = useAuth()
  const [projectModalVisibility, setProjectModalVisibility] = useState(false)
  const formMethods = useForm()
  const featureTagLayers = useSelector(
    (state: RootState) => state.map.layerData.organization
  )
  const activeFeatureTagLayers = useMemo(
    () => accumulateActiveLayers(featureTagLayers),
    [featureTagLayers]
  )
  const activeFeatureTagIds = activeFeatureTagLayers.map((layer) =>
    toInteger(layer.id)
  )
  const [featureCandidateData, setFeatureCandidateData] = useState(null)

  const { data: projectsData, loading: loadingProjects } = useQuery(
    PROJECT_BY_ORGANIZATION_QUERY,
    {
      variables: { organizationId: currentUser?.organizationId },
      skip: !currentUser?.organizationId,
    }
  )

  const { data: renderConfigsData, loading: loadingRenderConfigs } = useQuery(
    RENDER_CONFIGS_BY_ORG_QUERY,
    {
      variables: {
        input: {
          organizationId: currentUser?.organizationId,
          featureTypes: [featureType],
        },
      },
      skip: !currentUser?.organizationId,
    }
  )

  const renderConfigs = renderConfigsData ? renderConfigsData.renderConfigs : []
  const { data: featureTagsData } = useQuery(FEATURE_TAGS_QUERY)
  const flyoutConfig = useSelector((state: RootState) => state.map.flyoutConfig)
  const featureToUpdate = useSelector(
    (state: RootState) => state.map.featureToUpdate
  )
  const lastCreatedFeature = useSelector(
    (state: RootState) => state.map.lastCreatedFeature
  )

  useEffect(() => {
    if (featureToUpdate) {
      formMethods.reset(featureToUpdate)
    }
  }, [featureToUpdate, formMethods])

  type FormFields = {
    id?: string
    renderConfigId: string
    projectId: string
    description: string
    tagId: string
  }

  const handleSubmit: SubmitHandler<FormFields> = (data) => {
    if (currentUser === null) {
      toast.error('You must be logged in to create a point')
      return
    }

    const input: CreateFeatureInput | UpdateFeatureInput = {
      ...data,
      tagId: parseInt(data.tagId, 10),
      projectId: parseInt(data.projectId, 10),
      renderConfigId: parseInt(data.renderConfigId, 10),
    }

    if (!activeFeatureTagIds.includes(parseInt(data.tagId, 10))) {
      setFeatureCandidateData(input)
    } else {
      if (flyoutConfig.component === 'EditFeature') {
        onUpdate(input as UpdateFeatureInput)
      } else {
        onSave(input as CreateFeatureInput)
      }
    }
  }

  const transformedRenderConfigs =
    renderConfigs?.map(({ id, name }) => ({
      id,
      name,
      value: id,
    })) || []

  const transformedFeatureTags =
    featureTagsData?.featureTags.map(
      ({ id, displayName: name }: FeatureTag) => ({
        id,
        name,
        value: id,
      })
    ) || []

  const transformedProjects =
    projectsData?.projects.map(({ id, name }: Project) => ({
      id,
      name,
      value: id,
    })) || []

  return (
    <>
      <Form
        formMethods={formMethods}
        onSubmit={handleSubmit}
        error={creationError}
        className="space-y-2"
      >
        <FormError
          error={creationError}
          wrapperClassName="rw-form-error-wrapper"
          titleClassName="rw-form-error-title"
          listClassName="rw-form-error-list"
        />
        <Select
          name="renderConfigId"
          label="Type"
          placeholderText={`Select ${getFeatureTypeLabel(
            featureType
          ).toLowerCase()} type`}
          options={transformedRenderConfigs}
          loading={loadingRenderConfigs}
          validation={{ required: 'you must select a type' }}
        />
        <Input
          name="description"
          defaultValue={
            featureToUpdate?.properties.description ??
            lastCreatedFeature?.description
          }
          placeholderText="Describe your feature"
          label="Description"
          validation={{ required: true }}
        />
        <Select
          name="tagId"
          label="Layer"
          placeholderText="Select layer"
          options={transformedFeatureTags}
          defaultValue={
            featureToUpdate?.properties.layerId ?? lastCreatedFeature?.tagId
          }
          validation={{ required: 'you must select a layer' }}
        />
        <div className="flex flex-row justify-between items-center space-x-1">
          <Select
            name="projectId"
            label="Project"
            placeholderText="Select project"
            options={transformedProjects}
            defaultValue={
              featureToUpdate?.properties.projectId ??
              lastCreatedFeature?.projectId
            }
            loading={loadingProjects}
            fieldProps={{ className: 'w-full' }}
            validation={{ required: 'you must select a project' }}
          />
          <Button
            plain
            className="self-end"
            type="button"
            onClick={() => setProjectModalVisibility(true)}
          >
            <PlusIcon className="h-5 w-5" />
          </Button>
        </div>
        <div className="w-full flex justify-center">
          {flyoutConfig.component === 'CreateFeature' ? (
            <Button
              color="primary"
              type="submit"
              disabled={loading || !isAuthenticated}
              className="w-full mt-4"
            >
              {isAuthenticated ? 'Save' : 'Sign up to create a feature!'}
            </Button>
          ) : (
            <Button
              type="submit"
              color="primary"
              disabled={loading || !isAuthenticated}
              className="w-full mt-4"
            >
              Update
            </Button>
          )}
        </div>
      </Form>
      <CreateProjectDialog
        isOpen={projectModalVisibility}
        setIsOpen={setProjectModalVisibility}
      />
      <LayerInactiveWarningDialog
        isOpen={featureCandidateData !== null}
        setIsOpen={setFeatureCandidateData}
        featureCandidateData={featureCandidateData}
        featureTagsData={featureTagsData}
        onSave={flyoutConfig.component === 'CreateFeature' ? onSave : onUpdate}
        mode={flyoutConfig.component}
      />
    </>
  )
}

export default FeatureForm
