import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { selectBaseLayer } from '../Map/mapSlice'
import { Layer } from '../LayerSelector/LayerTypes'

type BaseLayerItemProps = {
  layer: Layer
}

const BaseLayerItem: FC<BaseLayerItemProps> = ({ layer }) => {
  const dispatch = useDispatch()
  const handleSelectBaseLayer = (selectedLayerId: Layer['id']) => {
    dispatch(selectBaseLayer(selectedLayerId))
  }
  return (
    <li
      id={`${layer.id}-baselayer`}
      className="p-1 sm:p-2 divide-y divide-gray-200 rounded-lg bg-white"
    >
      <button onClick={() => handleSelectBaseLayer(layer.id)}>
        <div className="grid grid-cols-1">
          <img
            className={`inline-block h-14 w-14 rounded-md border-2 ${
              layer.active
                ? 'border-blue-500 drop-shadow-lg'
                : 'border-gray-300 hover:border-blue-200'
            } hover:drop-shadow-md border-solid`}
            src={`/baseLayers/${layer.id}.jpeg`}
            alt=""
          />
          <h4
            className={`inline-block select-none mt-1 w-14 text-xs ${
              layer.active ? 'font-semibold drop-shadow-lg' : 'font-medium'
            } leading-2 text-gray-900 `}
          >
            {layer.displayName}
          </h4>
        </div>
      </button>
    </li>
  )
}

export default BaseLayerItem
