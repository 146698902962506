import { navigate, routes } from '@redwoodjs/router'
import Logo from '../Logo/Logo'

type Props = {
  handleClick?: () => void
  handleKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void
  className?: string
}

const LogoButton = ({
  handleClick = () => {
    navigate(routes.home())
  },
  handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      navigate(routes.home())
    }
  },
  className,
}: Props) => {
  return (
    <button
      className={`${className} cursor-pointer h-auto w-24 bg-transparent border-0 p-0`}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      type="button"
      aria-label="Go to home page"
    >
      <Logo />
    </button>
  )
}

export default LogoButton
