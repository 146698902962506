import Select from '../catalyst/rw/Select'
import { useFormContext } from '@redwoodjs/forms'
import { Field, ErrorMessage } from 'src/components/catalyst/fieldset'
import { Text } from 'src/components/catalyst/text'
import { fromApiFeatureType } from 'src/lib/featureUtils'
import { FeatureTag, Project } from 'types/graphql'
import { PlusIcon } from '@heroicons/react/24/outline'
import { Button } from '../catalyst/button'
import { useState } from 'react'
import CreateProjectDialog from '../Feature/CreateProjectDialog'
import { ApiFeatureType, ApiUploadType, FeatureType } from 'src/types/features'
import { RenderConfig } from 'types/graphql'

interface FeatureOption {
  id: number
  value: ApiFeatureType
  name: string
}

const featureOptions: FeatureOption[] = [
  {
    id: 1,
    value: 'point_geom',
    name: 'Point',
  },
  {
    id: 2,
    value: 'line_geom',
    name: 'Line',
  },
  {
    id: 3,
    value: 'polygon_geom',
    name: 'Polygon',
  },
]

interface UploadTypeOption {
  id: number
  value: ApiUploadType
  name: string
}

const getUploadTypesByApiFeatureType = (
  featureType: ApiFeatureType
): UploadTypeOption[] => {
  switch (featureType) {
    case 'point_geom':
      return [
        { id: 1, value: 'Control', name: 'Control' },
        { id: 2, value: 'Monument', name: 'Monument' },
      ]
    case 'line_geom':
      return [{ id: 1, value: 'Boundary', name: 'Boundary' }]
    case 'polygon_geom':
      return [{ id: 1, value: 'Boundary', name: 'Boundary' }]
    default:
      return []
  }
}

const FileUploadForm = ({
  setSelectedFile,
  renderConfigs,
  renderConfigsLoading,
  projects,
  loadingProjects,
  featureTags,
  loadingFeatureTags,
}) => {
  const [projectModalVisibility, setProjectModalVisibility] = useState(false)
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext()
  const apiFeatureType: ApiFeatureType = watch('apiFeatureType')
  const watchFile: File = watch('file')
  const featureType: FeatureType = fromApiFeatureType(apiFeatureType)

  const {
    onChange: fileOnChange,
    name: fileName,
    ref: fileRef,
  } = register('file', {
    required: 'file is required',
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null
    setSelectedFile(file)
  }

  const uploadTypesByGeometryType =
    getUploadTypesByApiFeatureType(apiFeatureType)

  const featureTypeFilteredRenderConfigs =
    renderConfigs
      ?.filter((config) => config.featureType === featureType)
      .map(({ id, name }: RenderConfig) => ({
        id,
        name,
        value: id,
      })) || []

  const transformedFeatureTags =
    featureTags?.map(({ id, displayName: name }: FeatureTag) => ({
      id,
      name,
      value: id,
    })) || []

  const transformedProjects =
    projects?.map(({ id, name }: Project) => ({
      id,
      name,
      value: id,
    })) || []

  return (
    <div className="w-full space-y-3">
      <Select
        name="apiFeatureType"
        options={featureOptions}
        label="Feature Types"
        className="w-full"
        validation={{ required: true }}
      />

      <div className="form-group">
        <Select
          name="renderConfigId"
          label="Apply Style"
          options={featureTypeFilteredRenderConfigs}
          loading={renderConfigsLoading}
          validation={{ required: true }}
        />
      </div>

      <div className="form-group">
        <Select
          name="type"
          options={uploadTypesByGeometryType}
          label="Type"
          validation={{ required: true }}
        />
      </div>

      <Select
        name="tagId"
        label="Layer"
        placeholderText="Select layer"
        options={transformedFeatureTags}
        loading={loadingFeatureTags}
        validation={{ required: 'you must select a layer' }}
      />
      <div className="flex flex-row justify-between items-center space-x-1">
        <Select
          name="projectId"
          label="Project"
          placeholderText="Select project"
          options={transformedProjects}
          loading={loadingProjects}
          fieldProps={{ className: 'w-full' }}
          validation={{ required: 'you must select a project' }}
        />
        <Button
          plain
          className="self-end"
          type="button"
          onClick={() => setProjectModalVisibility(true)}
        >
          <PlusIcon className="h-5 w-5" />
        </Button>
      </div>

      <div className="form-group flex gap-2 justify-center w-full items-centers">
        <Field className="flex flex-col items-center w-full">
          <input
            type="file"
            id="file"
            name={fileName}
            onChange={(e) => {
              handleFileChange(e)
              fileOnChange(e)
            }}
            className="form-control"
            ref={fileRef}
            style={{ display: 'none' }}
          />
          <div className="flex flex-row items-center justify-between w-full">
            <label
              htmlFor="file"
              className={`border-1 text-center bg-gray-200 px-2 py-[5px] ${
                watchFile && watchFile[0] ? 'w-1/2' : 'w-full'
              } rounded-md border-black cursor-pointer  hover:bg-secondary hover:bg-opacity-20 text-muted`}
            >
              Choose File
            </label>
            {watchFile && watchFile[0] && (
              <Text className="p-2 w-1/2">{watchFile[0].name}</Text>
            )}
          </div>
          {errors.file && (
            <ErrorMessage className="self-start">{`${errors.file.message}`}</ErrorMessage>
          )}
        </Field>
      </div>
      <CreateProjectDialog
        isOpen={projectModalVisibility}
        setIsOpen={setProjectModalVisibility}
      />
    </div>
  )
}

export default FileUploadForm
