import { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import SidebarSectionDisclosure from '../SidebarSectionDisclosure/SidebarSectionDisclosure'
import LayerTree from '../LayerTree/LayerTree'
import { Text } from '../catalyst/text'

const OrgLayerSelector: FC = () => {
  const viewState = useSelector((state: RootState) => state.map.viewState)
  const layerData = useSelector((state: RootState) => state.map.layerData)
  const zoomThreshold = useSelector(
    (state: RootState) => state.map.zoomThreshold
  )
  return (
    <SidebarSectionDisclosure
      label="Org. Layers"
      last={true}
      content={
        viewState.zoom > zoomThreshold ? (
          layerData.organization.length > 0 ? (
            <LayerTree
              layerData={layerData.organization}
              section="organization"
            />
          ) : (
            <div className="flex justify-center items-center w-full h-full">
              <Text className="p-6 font-xl">
                {"You haven't created any layers yet"}
              </Text>
            </div>
          )
        ) : (
          <div className="flex justify-center items-center w-full h-full">
            <h2 className="p-3 font-italic">
              Zoom to see available org. layers
            </h2>
          </div>
        )
      }
    />
  )
}

export default OrgLayerSelector
