import {
  UserCircleIcon,
  UsersIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'
import ProfileSection from './ProfileSection'
import TeamSection from './TeamSection'
import { useAuth } from 'src/auth'
import UserSettingsSection from './UserSettingsSection'
import SettingsHeader from 'src/components/SettingsHeader/SettingsHeader'

enum Section {
  General = 'General',
  Team = 'Team',
  User = 'User',
}

const navigationArr = [
  {
    name: Section.General,
    icon: UserCircleIcon,
    current: true,
  },

  {
    name: Section.Team,
    icon: UsersIcon,
    current: false,
  },
  {
    name: Section.User,
    icon: WrenchIcon,
    current: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SettingsPage = () => {
  const [sectionString, setSectionString] = useState('General')
  const [navigation, setNavigation] = useState(navigationArr)
  const { currentUser, loading } = useAuth()

  const handleItemClick = (clickedItem) => {
    const updatedNavigation = navigation.map((item) => ({
      ...item,
      current: item.name === clickedItem.name,
    }))
    setNavigation(updatedNavigation)
    setSectionString(clickedItem.name)
  }

  if (loading) return <div>Loading...</div>

  return (
    <div>
      <div className="mx-auto max-w-7xl pt-16 flex flex-col lg:flex-row justify-center lg:gap-x-16 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20 w-full justify-center">
          <nav className="flex-none px-2 sm:px-6 lg:px-0 w-full">
            <ul className="flex px-2 gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col justify-between sm:justify-evenly">
              {navigation.map((item) => (
                <li key={item.name}>
                  <button
                    onClick={() => handleItemClick(item)}
                    className={classNames(
                      item.current
                        ? 'bg-gray-50 text-secondary'
                        : 'text-gray-600 hover:text-secondary hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? 'text-secondary'
                          : 'text-gray-400 group-hover:text-secondary',
                        'h-6 w-6 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <div className="w-[100vw] lg:w-[50vw] sm:px-0 lg:p-0">
          <main className="p-6 sm:px-32 lg:flex-auto lg:px-0 lg:py-20 w-full">
            <SettingsHeader />
            {sectionString === Section.General && currentUser && (
              <ProfileSection />
            )}
            {sectionString === Section.Team && currentUser && (
              <TeamSection currentUser={currentUser} />
            )}
            {sectionString === Section.User && currentUser && (
              <UserSettingsSection />
            )}
          </main>
        </div>
      </div>
    </div>
  )
}

export default SettingsPage
