// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.

import { Router, Route, Set, PrivateSet } from '@redwoodjs/router'

import SettingsPage from './pages/SettingsPage/SettingsPage'
import MapLayoutProvider from './layouts/MapLayout/MapLayoutProvider'
import { useAuth } from './auth'
import { OnboardingProfile } from './pages/OnboardingPages/OnboardingProfile/OnboardingProfile'
import { OnboardingOrganization } from './pages/OnboardingPages/OnboardingOrganization/OnboardingOrganization'
import { OnboardingUserSettings } from './pages/OnboardingPages/OnboardingUserSettings/OnboardingUserSettings'
import { OnboardingDisclaimer } from './pages/OnboardingPages/OnboardingDisclaimer/OnboardingDisclaimer'
import { OnboardingBilling } from './pages/OnboardingPages/OnboardingBilling/OnboardingBilling'
import StyleGuide from './pages/StyleGuide/StyleGuide'
import OnboardingSeats from './pages/OnboardingPages/OnboardingSeats/OnboardingSeats'
import HomePage from './pages/HomePage/HomePage'
import InvitationPage from './pages/InvitationPage/InvitationPage'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route path="/invitation-page" page={InvitationPage} name="invitation" />
      <Route path="/hello" page={LeadPage} name="lead" />
      <Route path="/welcome" page={MarketingMapPage} name="marketingmap" />
      <Route path="/" page={HomePage} name="home" />

      <PrivateSet unauthenticated="home">
        <Route path="/onboardingProfile" page={OnboardingProfile} name="onboardingProfile" />
        <Route path="/onboardingOrganization" page={OnboardingOrganization} name="onboardingOrganization" />
        <Route path="/onboardingSeats" page={OnboardingSeats} name="onboardingSeats" />
        <Route path="/onboardingUserSettings" page={OnboardingUserSettings} name="onboardingUserSettings" />
        <Route path="/onboardingBilling" page={OnboardingBilling} name="onboardingBilling" />
        <Route path="/onboardingDisclaimer" page={OnboardingDisclaimer} name="onboardingDisclaimer" />
        <Route path="/settings" page={SettingsPage} name="settings" />
        <Route path="/styleguide" page={StyleGuide} name="styleGuide" />
      </PrivateSet>
      <Set wrap={MapLayoutProvider}>
        <PrivateSet unauthenticated="home">
          <Route path="/app" page={MapPage} name="map" />
        </PrivateSet>
      </Set>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
