import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from 'src/components/catalyst/dialog'
import { useMutation } from '@redwoodjs/web'
import { useQuery } from '@apollo/client'
import { LoaderIcon, toast } from '@redwoodjs/web/dist/toast'
import { useAuth } from 'src/auth'
import Input from 'src/components/catalyst/rw/Input'
import { Button } from 'src/components/catalyst/button'
import { Form, SubmitHandler } from '@redwoodjs/forms'
import { PencilIcon } from '@heroicons/react/24/outline'
import { UPDATE_ORGANIZATION_MUTATION } from 'src/queries/organization'

const ORGANIZATION_QUERY_UPDATE = gql`
  query organization($id: Int!) {
    organization(id: $id) {
      id
      name
      state
      address
      industry
      zipCode
      city
    }
  }
`

export default function UpdateOrganization({ setIsOpen, open }) {
  const { currentUser } = useAuth()

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION_MUTATION, {
    onCompleted: () => {
      toast.success('Organization updated')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const { data, loading } = useQuery(ORGANIZATION_QUERY_UPDATE, {
    variables: { id: currentUser.organizationId },
    skip: !currentUser.organizationId,
  })

  type FormFields = {
    orgName: string
    address: string
    city: string
    state: string
    industry: string
    zipcode: string
  }

  const handleSubmit: SubmitHandler<FormFields> = async (data) => {
    const input = {
      name: data.orgName,
      address: data.address,
      city: data.city,
      state: data.state,
      industry: data.industry,
      zipCode: data.zipcode,
    }
    await updateOrganization({
      variables: {
        input,
        id: currentUser.organizationId,
      },
    })
    setIsOpen(false)
  }

  if (loading) {
    return <LoaderIcon />
  }

  return (
    <>
      <PencilIcon
        className="h-6 w-6 cursor-pointer"
        color="#316328"
        onClick={() => setIsOpen(true)}
      />
      <Dialog open={open} onClose={() => setIsOpen(false)}>
        <Form onSubmit={handleSubmit}>
          <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
            Update Organization
          </DialogTitle>
          <DialogDescription>
            Update the organization&apos;s information below.
          </DialogDescription>
          <DialogBody className="space-y-2">
            <Input
              name="orgName"
              type="text"
              label="Organization Name"
              defaultValue={data?.organization.name}
            />
            <Input
              name="address"
              type="text"
              label="Address"
              defaultValue={data?.organization.address}
            />
            <Input
              name="city"
              type="text"
              label="City"
              defaultValue={data?.organization.city}
            />
            <div className="flex gap-4">
              <Input
                name="state"
                type="text"
                label="State"
                defaultValue={data?.organization.state}
              />
              <Input
                name="zipcode"
                type="text"
                label="Zip Code"
                defaultValue={data?.organization.zipCode}
              />
            </div>
            <Input
              name="industry"
              type="text"
              label="Industry"
              defaultValue={data?.organization.industry}
            />
          </DialogBody>
          <DialogActions>
            <Button plain onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}
