import { FC } from 'react'
import BaseLayerSelector from '../BaseLayerSelector/BaseLayerSelector'
import PublicLayerSelector from '../PublicLayerSelector/PublicLayerSelector'
import OrgLayerSelector from '../OrgLayerSelector/OrgLayerSelector'

const LayerSelector: FC = () => {
  return (
    <div id="layer-selector">
      <BaseLayerSelector />
      <PublicLayerSelector />
      <OrgLayerSelector />
    </div>
  )
}

export default LayerSelector
